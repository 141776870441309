/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCameraImageByVIN = /* GraphQL */ `query GetCameraImageByVIN($cameraID: ID, $vin: String!) {
  getCameraImageByVIN(cameraID: $cameraID, vin: $vin) {
    timestamp
    value
  }
}
` as GeneratedQuery<
  APITypes.GetCameraImageByVINQueryVariables,
  APITypes.GetCameraImageByVINQuery
>;
export const getHealthStatus = /* GraphQL */ `query GetHealthStatus {
  getHealthStatus {
    status
  }
}
` as GeneratedQuery<
  APITypes.GetHealthStatusQueryVariables,
  APITypes.GetHealthStatusQuery
>;
export const getHub = /* GraphQL */ `query GetHub($id: ID!) {
  getHub(id: $id) {
    active
    address
    createdAt
    id
    name
    position {
      altitude
      heading
      latitude
      longitude
    }
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetHubQueryVariables, APITypes.GetHubQuery>;
export const getMission = /* GraphQL */ `query GetMission($id: ID!) {
  getMission(id: $id) {
    actualArrivalTime
    actualStartTime
    createdAt
    description
    events {
      createdAt
      position {
        altitude
        heading
        latitude
        longitude
      }
      type
    }
    id
    name
    plannedArrivalTime
    progressDetails {
      eta
      remainingDistanceInMeters
      traveledDistanceInMeters
      updatedAt
    }
    route {
      createdAt
      durationInSeconds
      externalRouteId
      landingHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      landingHubId
      launchHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      launchHubId
      predefinedRouteId
      totalDistanceInMeters
      updatedAt
      waypoints {
        altitude
        heading
        latitude
        longitude
      }
    }
    state {
      updatedAt
      value
    }
    updatedAt
    vin
  }
}
` as GeneratedQuery<
  APITypes.GetMissionQueryVariables,
  APITypes.GetMissionQuery
>;
export const getVehicleByVIN = /* GraphQL */ `query GetVehicleByVIN($vin: String!) {
  getVehicleByVIN(vin: $vin) {
    activeMissionId
    adsState
    cameras {
      id
      name
    }
    connectivityStatus {
      description
      expectedDisconnect
      latency
      reason
      state
    }
    createdAt
    customerId
    deviations {
      category
      description
      id
      isBlocking
      origin
      sentAt
      severity
    }
    drivingState
    externalId
    latestVehicleUpdate
    licensePlate
    mrmState {
      updatedAt
      value
    }
    name
    owners {
      email
      fullName
      id
      phoneNumber
      position
    }
    position {
      altitude
      heading
      latitude
      longitude
    }
    speedInKmh
    speedInMs
    updatedAt
    vin
    weightInKg
  }
}
` as GeneratedQuery<
  APITypes.GetVehicleByVINQueryVariables,
  APITypes.GetVehicleByVINQuery
>;
export const getVideoStreamingURL = /* GraphQL */ `query GetVideoStreamingURL($cameraID: String, $vin: String!) {
  getVideoStreamingURL(cameraID: $cameraID, vin: $vin) {
    cameraID
    streamingURL
    vin
  }
}
` as GeneratedQuery<
  APITypes.GetVideoStreamingURLQueryVariables,
  APITypes.GetVideoStreamingURLQuery
>;
export const listExceptions = /* GraphQL */ `query ListExceptions {
  listExceptions {
    createdAt
    exceptionEnd
    exceptionSegment {
      direction
      from
      to
    }
    exceptionStart
    exceptionType
    id
    status
  }
}
` as GeneratedQuery<
  APITypes.ListExceptionsQueryVariables,
  APITypes.ListExceptionsQuery
>;
export const listHubs = /* GraphQL */ `query ListHubs($limit: Int, $nextToken: String) {
  listHubs(limit: $limit, nextToken: $nextToken) {
    active
    address
    createdAt
    id
    name
    position {
      altitude
      heading
      latitude
      longitude
    }
    updatedAt
  }
}
` as GeneratedQuery<APITypes.ListHubsQueryVariables, APITypes.ListHubsQuery>;
export const listRoadSegmentStatus = /* GraphQL */ `query ListRoadSegmentStatus {
  listRoadSegmentStatus {
    createdAt
    id
    reportedFromSourceAt
    restrictionCauses
    restrictionEnd
    restrictionStart
    segment {
      direction
      from
      to
      waypoints {
        altitude
        heading
        latitude
        longitude
      }
    }
    source
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.ListRoadSegmentStatusQueryVariables,
  APITypes.ListRoadSegmentStatusQuery
>;
export const listRoutes = /* GraphQL */ `query ListRoutes {
  listRoutes {
    averageDuration
    createdAt
    distance
    id
    landingHub {
      active
      address
      createdAt
      id
      name
      position {
        altitude
        heading
        latitude
        longitude
      }
      updatedAt
    }
    launchHub {
      active
      address
      createdAt
      id
      name
      position {
        altitude
        heading
        latitude
        longitude
      }
      updatedAt
    }
    updatedAt
    waypoints {
      altitude
      heading
      latitude
      longitude
    }
  }
}
` as GeneratedQuery<
  APITypes.ListRoutesQueryVariables,
  APITypes.ListRoutesQuery
>;
export const listVehicles = /* GraphQL */ `query ListVehicles {
  listVehicles {
    activeMissionId
    adsState
    cameras {
      id
      name
    }
    connectivityStatus {
      description
      expectedDisconnect
      latency
      reason
      state
    }
    createdAt
    customerId
    deviations {
      category
      description
      id
      isBlocking
      origin
      sentAt
      severity
    }
    drivingState
    externalId
    latestVehicleUpdate
    licensePlate
    mrmState {
      updatedAt
      value
    }
    name
    owners {
      email
      fullName
      id
      phoneNumber
      position
    }
    position {
      altitude
      heading
      latitude
      longitude
    }
    speedInKmh
    speedInMs
    updatedAt
    vin
    weightInKg
  }
}
` as GeneratedQuery<
  APITypes.ListVehiclesQueryVariables,
  APITypes.ListVehiclesQuery
>;

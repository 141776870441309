/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onUpdateHub = /* GraphQL */ `subscription OnUpdateHub {
  onUpdateHub {
    active
    address
    createdAt
    id
    name
    position {
      altitude
      heading
      latitude
      longitude
    }
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateHubSubscriptionVariables,
  APITypes.OnUpdateHubSubscription
>;
export const onUpdateMission = /* GraphQL */ `subscription OnUpdateMission($id: ID!) {
  onUpdateMission(id: $id) {
    actualArrivalTime
    actualStartTime
    createdAt
    description
    events {
      createdAt
      position {
        altitude
        heading
        latitude
        longitude
      }
      type
    }
    id
    name
    plannedArrivalTime
    progressDetails {
      eta
      remainingDistanceInMeters
      traveledDistanceInMeters
      updatedAt
    }
    route {
      createdAt
      durationInSeconds
      externalRouteId
      landingHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      landingHubId
      launchHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      launchHubId
      predefinedRouteId
      totalDistanceInMeters
      updatedAt
      waypoints {
        altitude
        heading
        latitude
        longitude
      }
    }
    state {
      updatedAt
      value
    }
    updatedAt
    vin
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMissionSubscriptionVariables,
  APITypes.OnUpdateMissionSubscription
>;
export const onUpdateRoadSegmentStatus = /* GraphQL */ `subscription OnUpdateRoadSegmentStatus {
  onUpdateRoadSegmentStatus {
    createdAt
    id
    reportedFromSourceAt
    restrictionCauses
    restrictionEnd
    restrictionStart
    segment {
      direction
      from
      to
      waypoints {
        altitude
        heading
        latitude
        longitude
      }
    }
    source
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRoadSegmentStatusSubscriptionVariables,
  APITypes.OnUpdateRoadSegmentStatusSubscription
>;
export const onUpdateVehicle = /* GraphQL */ `subscription OnUpdateVehicle {
  onUpdateVehicle {
    activeMissionId
    adsState
    cameras {
      id
      name
    }
    connectivityStatus {
      description
      expectedDisconnect
      latency
      reason
      state
    }
    createdAt
    customerId
    deviations {
      category
      description
      id
      isBlocking
      origin
      sentAt
      severity
    }
    drivingState
    externalId
    latestVehicleUpdate
    licensePlate
    mrmState {
      updatedAt
      value
    }
    name
    owners {
      email
      fullName
      id
      phoneNumber
      position
    }
    position {
      altitude
      heading
      latitude
      longitude
    }
    speedInKmh
    speedInMs
    updatedAt
    vin
    weightInKg
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVehicleSubscriptionVariables,
  APITypes.OnUpdateVehicleSubscription
>;
export const onUpdateVideoStreamingError = /* GraphQL */ `subscription OnUpdateVideoStreamingError($cameraID: String, $vin: String!) {
  onUpdateVideoStreamingError(cameraID: $cameraID, vin: $vin) {
    cameraID
    errorCode
    errorMessage
    vin
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVideoStreamingErrorSubscriptionVariables,
  APITypes.OnUpdateVideoStreamingErrorSubscription
>;

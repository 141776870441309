import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/navigation/Tabs'
import FleetsTable from 'features/settings/FleetsSettingsTable'
import TabContentHeader from 'features/settings/TabContentHeader'
import TeamsTable from 'features/settings/TeamsSettingsTable'
import { User } from 'features/settings/UserRow'
import { UserStatus } from 'features/settings/UserRowStatus'
import UsersTable from 'features/settings/UsersSettingsTable'
import { faker } from '@faker-js/faker'
import { Team } from 'store/slices/settingsPageSlice'

const users: User[] = [
  {
    id: faker.string.uuid(),
    firstName: faker.person.firstName(),
    lastName: faker.person.lastName(),
    email: faker.internet.email(),
    phone: faker.phone.number(),
    country: faker.location.country(),
    city: faker.location.city(),
    team: 'string',
    role: 'string',
    status: UserStatus.ACTIVE,
    created: String(faker.date.anytime().toISOString())
  },
  {
    id: faker.string.uuid(),
    firstName: faker.person.firstName(),
    lastName: faker.person.lastName(),
    email: faker.internet.email(),
    phone: faker.phone.number(),
    country: faker.location.country(),
    city: faker.location.city(),
    team: 'string',
    role: 'string',
    status: UserStatus.INVITED,
    created: String(faker.date.anytime().toISOString())
  }
]

const teams: Team[] = [
  {
    id: faker.string.uuid(),
    name: faker.company.name(),
    numberOfUsers: faker.number.int({ min: 0, max: 100 }),
    numberOfFleets: faker.number.int({ min: 0, max: 100 }),
    created: String(faker.date.anytime().toISOString())
  },
  {
    id: faker.string.uuid(),
    name: faker.company.name(),
    numberOfUsers: faker.number.int({ min: 0, max: 100 }),
    numberOfFleets: faker.number.int({ min: 0, max: 100 }),
    created: String(faker.date.anytime().toISOString())
  }
]

const SettingsPage = () => {
  return (
    <main className="flex h-full flex-col bg-white px-50 pt-50" data-testid="settings-page-content">
      <div>
        <h4 className="font-bold">Settings</h4>
      </div>
      <div className="mt-15">
        <Tabs defaultValue="users-tab" orientation="vertical">
          <TabsList aria-label="vehicle detail tabs">
            <TabsTrigger data-testid="settings-tab-users" value="users-tab">
              Users
            </TabsTrigger>
            <TabsTrigger data-testid="settings-tab-teams" value="teams-tab">
              Teams
            </TabsTrigger>
            <TabsTrigger data-testid="settings-tab-fleets" value="fleets-tab">
              Fleets
            </TabsTrigger>
          </TabsList>
          <TabsContent value="users-tab">
            <div className="mb-[30px] mt-15">
              <TabContentHeader
                title="Users"
                message="Manage user accesses and roles"
                buttonLabel="Add new user"
              />
            </div>
            <div>
              <UsersTable isLoading={false} users={users} />
            </div>
          </TabsContent>
          <TabsContent forceMount value="teams-tab">
            <div className="mb-[30px] mt-15">
              <TabContentHeader
                title="Teams"
                message="Manage user and fleet assignments"
                buttonLabel="Add new team"
              />
            </div>
            <div>
              <TeamsTable isLoading={false} teams={teams} />
            </div>
          </TabsContent>
          <TabsContent forceMount value="fleets-tab">
            <div className="mb-[30px] mt-15">
              <TabContentHeader title="Fleets" buttonLabel="Add new fleet" />
            </div>
            <div>
              <FleetsTable isLoading={false} fleets={[]} />
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </main>
  )
}

export default SettingsPage

import RIOApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader'

export interface NavItem {
  key: string
  route: React.ReactNode
}

interface MainNavigationProps {
  showHomeIcon?: boolean
  homeRoute?: React.ReactNode
  navItems?: NavItem[]
  actionBarItems?: React.ReactNode[]
}

const MainNavigation = ({
  showHomeIcon,
  homeRoute,
  navItems,
  actionBarItems
}: MainNavigationProps) => {
  return (
    <RIOApplicationHeader
      showHomeIcon={showHomeIcon}
      homeRoute={homeRoute}
      navItems={navItems}
      actionBarItems={actionBarItems}
      label={<p>Control Center</p>}
      className="[&_.navbar-brand]:border-none"
    />
  )
}

export default MainNavigation

import { LogsEvent, datadogLogs } from '@datadog/browser-logs'
import { v4 } from 'uuid'

const isProd = import.meta.env.PROD

const getEnvironment = () => {
  return import.meta.env.VITE_ID === 'production' ? 'prod' : 'staging'
}
const getServiceName = () => {
  return `control-center-web-${getEnvironment()}`
}

if (isProd) {
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    env: getEnvironment(),
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['info'],
    site: import.meta.env.VITE_DATADOG_SITE,
    sessionSampleRate: 100,
    service: getServiceName(),
    beforeSend: (event: LogsEvent) => {
      if (event.view) {
        if (event.view.url) {
          event.view.url = event.view.url.replace(/access_token=[^&]*/, 'access_token=REDACTED')
          event.view.url = event.view.url.replace(/id_token=[^&]*/, 'id_token=REDACTED')
        }
        if (event.view.referrer) {
          event.view.referrer = event.view.referrer.replace(
            /access_token=[^&]*/,
            'access_token=REDACTED'
          )
          event.view.referrer = event.view.referrer.replace(/id_token=[^&]*/, 'id_token=REDACTED')
        }
      }
      if (event.http && event.http.url) {
        event.http.url = event.http.url.replace(/access_token=[^&]*/, 'access_token=REDACTED')
        event.http.url = event.http.url.replace(/id_token=[^&]*/, 'id_token=REDACTED')
      }
      if (event.message) {
        event.message = event.message.replace(/access_token=[^&]*/, 'access_token=REDACTED')
        event.message = event.message.replace(/id_token=[^&]*/, 'id_token=REDACTED')
      }
      if (event.error && event.error.stack) {
        event.error.stack = event.error.stack.replace(/access_token=[^&]*/, 'access_token=REDACTED')
        event.error.stack = event.error.stack.replace(/id_token=[^&]*/, 'id_token=REDACTED')
      }
      return true
    }
  })

  const manControlCenter = 'man-control-center'
  const man_control_center = 'man_control_center'

  datadogLogs.setGlobalContext({
    account_id: import.meta.env.VITE_ACCOUNT_ID,
    team_identifier: import.meta.env.VITE_TEAM_IDENTIFIER,
    account_name: manControlCenter,
    team: man_control_center,
    'datadog.index': import.meta.env.VITE_TEAM_IDENTIFIER
  })
}

export const getSessionID = (): string => {
  let sessionId = localStorage.getItem('sessionId')
  if (!sessionId) {
    sessionId = v4()
    localStorage.setItem('sessionId', sessionId)
  }
  return sessionId
}

export const logPageViews = (pageName: string, sessionId: string) => {
  datadogLogs.logger.info('Page viewed', {
    page: pageName,
    sessionId
  })
}

import ContentLoader from 'components/content/ContentLoader'

const ScenarioItemSkeleton = () => {
  return (
    <div className="flex w-full items-center justify-between bg-white p-10">
      <ContentLoader height="14px" width="80%" />
      <div className="flex items-center justify-center rounded-xl border p-5">
        <span className="rioglyph rioglyph-play text-16 text-gray-darkest" />
      </div>
    </div>
  )
}

export default ScenarioItemSkeleton

import Button from 'components/actions/Button'
import NoContent from './NoContent'
import TeamSettingsRow from './TeamRow'
import { Team } from 'store/slices/settingsPageSlice'

interface TeamsTableProps {
  teams: Team[]
  isLoading: boolean
}

const tableHeaders = {
  name: 'Name',
  numberOfUsers: 'No. of users',
  numberOfFleets: 'No. of fleets',
  created: 'Created'
}

const TeamsTable = ({ teams, isLoading }: TeamsTableProps) => {
  if (isLoading) {
    return <div>Eskeleton</div>
  }

  return (
    <div className="flex flex-col">
      <table data-testid="exceptions-table" className="table-head-filled table-bordered table">
        <thead>
          <tr>
            {Object.values(tableHeaders).map((label) => (
              <th key={label}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? 'Loading...' : teams.map((_) => <TeamSettingsRow key={_.id} team={_} />)}
        </tbody>
      </table>
      {!teams.length && !isLoading && (
        <NoContent
          title="No teams yet, but they will appear here"
          body="As of now, there are no teams in the Control Center."
          button={
            <Button data-testid="no-content-teams-button" style="primary">
              <span className="rioglyph rioglyph-plus" />
              Add new team
            </Button>
          }
        />
      )}
    </div>
  )
}

export default TeamsTable

import { useEffect } from 'react'
import { decode } from '@here/flexpolyline'
import { Position } from '@rio-cloud/rio-uikit/mapTypes'

const here_api_key = import.meta.env.VITE_MAP_SERVICE_API_KEY

interface Props {
  startPoint: Position | undefined
  intermediatePoint: Position | undefined
  destinationPoint: Position | undefined
  setRoute(route: Position[] | undefined): void
}

const useGetRoute = ({ startPoint, intermediatePoint, destinationPoint, setRoute }: Props) => {
  const isIntermediateValid = intermediatePoint?.lat !== 0 && intermediatePoint?.lng !== 0

  const getRoute = async () => {
    const pathQueryString = `origin=${startPoint?.lat},${startPoint?.lng}&${isIntermediateValid ? `&via=${intermediatePoint?.lat},${intermediatePoint?.lng}!passThrough=true` : ''}&destination=${destinationPoint?.lat},${destinationPoint?.lng}`
    const url = `https://router.hereapi.com/v8/routes?transportMode=truck&${pathQueryString}&return=polyline,summary,turnByTurnActions&apiKey=${here_api_key}`
    try {
      const res = await fetch(url, {
        method: 'POST',
        body: '{}',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await res.json()
      const polyline = data.routes[0].sections[0].polyline
      const decodedPolyline = decode(polyline)
      const decodedRoute = decodedPolyline.polyline.map((_: number[]): Position => {
        if (!_[0] || !_[1]) {
          throw Error('A coordinate could not be found')
        }
        return { lat: _[0], lng: _[1] }
      })
      setRoute(decodedRoute)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (
      startPoint?.lat !== 0 &&
      startPoint?.lng !== 0 &&
      destinationPoint?.lat !== 0 &&
      destinationPoint?.lng !== 0
    ) {
      getRoute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPoint, destinationPoint, intermediatePoint])
}

export default useGetRoute

import { ReactNode } from 'react'
import SidebarSectionRow from './SidebarSectionRow'

export interface SidebarSectionItem {
  label: string
  element: ReactNode | string
}

interface SidebarSectionProps {
  items: SidebarSectionItem[]
}

const SidebarSection = ({ items }: SidebarSectionProps) => {
  return (
    <ul className="list-none p-0 pl-10">
      {items.map((_) => (
        <SidebarSectionRow key={_.label} label={_.label}>
          {_.element}
        </SidebarSectionRow>
      ))}
    </ul>
  )
}

export default SidebarSection

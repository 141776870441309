import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActiveSidebar,
  getActiveSidebar,
  getActiveTeam,
  setActiveTeam,
  Team
} from 'store/slices/settingsPageSlice'

interface TeamSettingsRowProps {
  team: Team
}

const TeamSettingsRow = ({ team }: TeamSettingsRowProps) => {
  const dispatch = useDispatch()
  const activeTeam = useSelector(getActiveTeam)
  const activeSidebar = useSelector(getActiveSidebar)
  const isActive = activeTeam?.id === team.id && activeSidebar === ActiveSidebar.TEAMS

  const selectTeam = (event: Event) => {
    const element = event.target as HTMLInputElement
    const elementTestId = element.getAttribute('data-testid')
    if (elementTestId && elementTestId.includes('team-settings-row-info')) {
      dispatch(setActiveTeam(team))
    }
  }

  useEffect(() => {
    const element = document.getElementById(team.id)
    if (element) {
      element.addEventListener('click', selectTeam)
      return () => {
        element.removeEventListener('click', selectTeam)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectTeam])

  return (
    <tr id={team.id} className={`cursor-pointer items-center ${isActive && 'active'}`}>
      <td data-testid="team-settings-row-info-firstName">{team.name}</td>
      <td data-testid="team-settings-row-info-lastName">{team.numberOfUsers}</td>
      <td data-testid="team-settings-row-info-email">{team.numberOfFleets}</td>
      <td data-testid="team-settings-row-info-phone">{team.created}</td>
    </tr>
  )
}

export default TeamSettingsRow

import React from 'react'
import { PropsWithTestId } from '../types'

interface StatesProps {
  icon?: React.ReactNode
  /**
   * Set to true when component is used on a dark background
   */
  onDark?: boolean
  title: string
  body: React.ReactNode | string
  button: React.ReactNode
}

const States = ({
  button,
  title,
  body,
  icon,
  onDark,
  'data-testid': dataTestId
}: PropsWithTestId<StatesProps>) => {
  return (
    <div
      data-testid={dataTestId}
      className={`mt-50 flex w-full flex-col items-center ${onDark && 'text-gray-light'}`}
    >
      {icon}
      <p className="text-center font-display text-h3 font-bold text-gray-dark">{title}</p>
      <p className="max-w-[500px] p-15 text-center text-14 text-gray-dark">{body}</p>
      <div className="mt-20 flex items-center gap-10">{button}</div>
    </div>
  )
}

export default States

import NoData from 'components/content/NoData'
import { isDate } from 'remeda'
import { ValueOrNoDataProps } from './ValueOrNoData'
import DateTime from 'components/content/DateTime'

const DateTimeOrNoData = ({ value }: ValueOrNoDataProps) =>
  value && isDate(new Date(value)) ? (
    <p className="w-max flex-1">
      <DateTime date={new Date(value)} />
    </p>
  ) : (
    <NoData text="No data" className="flex-1 text-14" />
  )

export default DateTimeOrNoData

export const ROUTES = {
  DEFAULT: '/',
  VEHICLE: 'vehicle',
  VEHICLE_BY_VIN: 'vehicle/:vin?',
  EXCEPTIONS: 'exceptions',
  SIMULATIONS: 'simulations',
  SETTINGS: 'settings'
} as const

export type Route = (typeof ROUTES)[keyof typeof ROUTES]

import { createApi } from '@reduxjs/toolkit/query/react'
import { HubSchema } from 'APIzod'
import { client } from 'graphql/client'
import { z } from 'zod'
import { listHubs } from 'graphql/queries'
import { Hub } from 'API'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { errorHandler } from './errors'

export const hubsApi = createApi({
  reducerPath: 'hubs',
  baseQuery: graphqlRequestBaseQuery({
    url: '/graphql'
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    listHubs: builder.query<Hub[], string>({
      queryFn: async () => {
        try {
          const { data } = await client.graphql({ query: listHubs })
          return { data: z.array(HubSchema()).parse(data.listHubs) }
        } catch (error) {
          throw errorHandler('Unexpected error occurred while fetching the hubs', error)
        }
      }
    })
  })
})

export const { useListHubsQuery, useLazyListHubsQuery } = hubsApi

import Button from 'components/actions/Button'

interface TabContentHeaderProps {
  title: string
  message?: string
  buttonLabel: string
  onClick?(): void
}

const TabContentHeader = ({ title, message, onClick, buttonLabel }: TabContentHeaderProps) => (
  <div className="flex items-center justify-between">
    <div className="flex flex-col">
      <h5 className="mb-5 font-bold">{title}</h5>
      {message ? (
        <p className="text-gray-darker">
          {message}
          <span className="rioglyph rioglyph-info-sign ml-5 text-16" />
        </p>
      ) : (
        <i>No info here.</i>
      )}
    </div>
    <Button data-testid="tab-content-header-button" style="primary" onClick={onClick}>
      <span className="rioglyph rioglyph-plus" />
      {buttonLabel}
    </Button>
  </div>
)

export default TabContentHeader

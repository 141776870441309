import Button from 'components/actions/Button'
import NoContent from './NoContent'
import UserSettingsRow, { User } from './UserRow'

interface UsersTableProps {
  users: User[]
  isLoading: boolean
}

const tableHeaders = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'E-mail',
  phoneNumber: 'Phone number',
  country: 'Country',
  city: 'City',
  team: 'Team',
  role: 'Role',
  status: 'Status',
  created: 'Created',
  _: ''
}

const UsersTable = ({ users, isLoading }: UsersTableProps) => {
  return (
    <div className="flex flex-col">
      <table data-testid="exceptions-table" className="table-head-filled table-bordered table">
        <thead>
          <tr>
            {Object.values(tableHeaders).map((label) => (
              <th key={label}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? 'Loading...' : users.map((_) => <UserSettingsRow key={_.email} user={_} />)}
        </tbody>
      </table>
      {!users.length && !isLoading && (
        <NoContent
          title="No users yet, but they will appear here"
          body="As of now, there are no users in the Control Center. Add a user to assign roles and add to teams."
          button={
            <Button data-testid="no-content-fleets-button" style="primary">
              <span className="rioglyph rioglyph-plus" />
              Add new user
            </Button>
          }
        />
      )}
    </div>
  )
}

export default UsersTable

import DateTimeOrNoData from 'features/vehicle-key-data/DateTimeOrNoData'
import UserRowStatus, { UserStatus } from './UserRowStatus'
import { useDispatch, useSelector } from 'react-redux'
import {
  setActiveUser,
  getActiveUser,
  getActiveSidebar,
  ActiveSidebar
} from 'store/slices/settingsPageSlice'
import { useEffect } from 'react'
import { ButtonDropdown } from '@rio-cloud/rio-uikit'

// TODO: this interface should be updated based on schema - still not defined
export interface User {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  country: string
  city: string
  team: string
  role: string
  status: UserStatus
  created: string
}

interface UserSettingsRowProps {
  user: User
}

const UserSettingsRow = ({ user }: UserSettingsRowProps) => {
  const dispatch = useDispatch()
  const activeUser = useSelector(getActiveUser)
  const activeSidebar = useSelector(getActiveSidebar)
  const isActive = activeUser?.id === user.id && activeSidebar === ActiveSidebar.USERS

  const selectUser = (event: Event) => {
    const element = event.target as HTMLInputElement
    const elementTestId = element.getAttribute('data-testid')
    if (elementTestId && elementTestId.includes('user-settings-row-info')) {
      dispatch(setActiveUser(user))
    }
  }

  const openSidebar = () => {
    dispatch(setActiveUser(user))
  }

  const dropdownItems = [
    {
      value: (
        <span className="flex items-center text-14 text-primary">
          <span className="rioglyph rioglyph-pencil text-[17px]" />
          <p className="leading-none ml-5">Edit</p>
        </span>
      ),
      onSelect: () => openSidebar()
    },
    {
      value: (
        <span className="flex items-center text-14 text-danger">
          <span className="rioglyph rioglyph-off text-[17px]" />
          <p className="leading-none ml-5">Deactivate</p>
        </span>
      ),
      onSelect: () => console.info('Deactivate clicked!'),
      disabled: true
    }
  ]

  useEffect(() => {
    const element = document.getElementById(user.id)
    if (element) {
      element.addEventListener('click', selectUser)
      return () => {
        element.removeEventListener('click', selectUser)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectUser])

  return (
    <tr id={user.id} className={`cursor-pointer items-center ${isActive && 'active'}`}>
      <td data-testid="user-settings-row-info-firstName">{user.firstName}</td>
      <td data-testid="user-settings-row-info-lastName">{user.lastName}</td>
      <td data-testid="user-settings-row-info-email">{user.email}</td>
      <td data-testid="user-settings-row-info-phone">{user.phone}</td>
      <td data-testid="user-settings-row-info-country">{user.country}</td>
      <td data-testid="user-settings-row-info-city">{user.city}</td>
      <td data-testid="user-settings-row-info-team">{user.team}</td>
      <td data-testid="user-settings-row-info-role">{user.role}</td>
      <td data-testid="user-settings-row-info-status">
        <UserRowStatus status={user.status} />
      </td>
      <td data-testid="user-settings-row-info-created">
        <DateTimeOrNoData value={user.created} />
      </td>
      <td data-testid="user-settings-row-dropdown-icon" className="flex justify-end">
        <ButtonDropdown
          title={
            <span className="rioglyph rioglyph-option-vertical cursor-pointer text-20 text-primary" />
          }
          items={dropdownItems}
          bsStyle="muted"
          iconOnly
          dropup
          noCaret
        />
      </td>
    </tr>
  )
}

export default UserSettingsRow

import * as RadixTabs from '@radix-ui/react-tabs'
import { PropsWithChildren } from 'react'

/*
 * The Tabs can be used to toggle between content without having the components (un)mount.
 * Visually, the tabs are adapted to the RIO Subnavigation component.
 */
export const Tabs = ({
  children,
  defaultValue,
  orientation
}: PropsWithChildren<{
  defaultValue: string
  orientation: 'vertical' | 'horizontal'
}>) => (
  <RadixTabs.Root defaultValue={defaultValue} orientation={orientation}>
    {children}
  </RadixTabs.Root>
)
export const TabsTrigger = ({ children, value }: PropsWithChildren<{ value: string }>) => (
  <RadixTabs.Trigger className="tab" value={value}>
    {children}
  </RadixTabs.Trigger>
)
export const TabsContent = ({
  forceMount,
  children,
  value
}: PropsWithChildren<{ value: string; forceMount?: true | undefined }>) => (
  <RadixTabs.Content
    className="tab-content data-[state=inactive]:hidden"
    forceMount={forceMount}
    value={value}
  >
    {children}
  </RadixTabs.Content>
)
export const TabsList = ({ children }: PropsWithChildren) => (
  <RadixTabs.List className="tab-header">{children}</RadixTabs.List>
)

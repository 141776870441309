import { FC, PropsWithChildren, memo } from 'react'
import VehicleDeviation from './VehicleDeviation'
import EmptyDeviationList from './EmptyDeviationList'
import { DeviationWithVIN } from 'helper/types'
import ErrorBoundary from 'components/ErrorBoundary'
import ErrorFallback from 'components/feedback/ErrorFallback'
import DeviationListSpinner from './DeviationListSpinner'

interface PrioritizedDeviationListProps {
  errors?: DeviationWithVIN[]
  warnings?: DeviationWithVIN[]
  isLoading: boolean
  isEmpty: boolean
  filter?: () => void
}

const VehicleDeviationListContainer: FC<PropsWithChildren> = ({ children }) => {
  return <div className="w-full p-0">{children}</div>
}

const PrioritizedDeviationList = ({
  errors,
  warnings,
  isLoading,
  isEmpty
}: PrioritizedDeviationListProps) => {
  if (isLoading) {
    return <DeviationListSpinner />
  }

  if (!isLoading && isEmpty) {
    return <EmptyDeviationList />
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <VehicleDeviationListContainer>
          {errors
            ?.sort(
              (firstDeviation, secondDeviation) =>
                Date.parse(firstDeviation.sentAt) - Date.parse(secondDeviation.sentAt)
            )
            .map((error) => {
              return <VehicleDeviation key={error.id + error.vin} deviation={error} />
            })}
        </VehicleDeviationListContainer>
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <VehicleDeviationListContainer>
          {warnings
            ?.sort(
              (firstDeviation, secondDeviation) =>
                Date.parse(firstDeviation.sentAt) - Date.parse(secondDeviation.sentAt)
            )
            .map((warning) => {
              return <VehicleDeviation key={warning.id + warning.vin} deviation={warning} />
            })}
        </VehicleDeviationListContainer>
      </ErrorBoundary>
    </>
  )
}

export default memo(PrioritizedDeviationList)

/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ArchiveMissionInput = {
  missionId: string,
};

export type Mission = {
  __typename?: "Mission",
  actualArrivalTime?: string | null,
  actualStartTime?: string | null,
  createdAt: string,
  description?: string | null,
  events?:  Array<MissionEvent > | null,
  id: string,
  name: string,
  plannedArrivalTime?: string | null,
  progressDetails?: MissionProgressDetails | null,
  route?: GeneratedRoute | null,
  state: MissionState,
  updatedAt: string,
  vin: string,
};

export type MissionEvent = {
  __typename?: "MissionEvent",
  createdAt: string,
  position: Position,
  type: MissionEventType,
};

export type Position = {
  __typename?: "Position",
  altitude?: number | null,
  heading?: number | null,
  latitude?: number | null,
  longitude?: number | null,
};

export enum MissionEventType {
  MISSION_ARCHIVED = "MISSION_ARCHIVED",
  MISSION_ASSIGNED = "MISSION_ASSIGNED",
  MISSION_CANCELED = "MISSION_CANCELED",
  MISSION_COMPLETED = "MISSION_COMPLETED",
  MISSION_PAUSED_BY_MRM = "MISSION_PAUSED_BY_MRM",
  MISSION_RESET = "MISSION_RESET",
  MISSION_RESUMED = "MISSION_RESUMED",
  MISSION_STARTED = "MISSION_STARTED",
}


export type MissionProgressDetails = {
  __typename?: "MissionProgressDetails",
  eta: string,
  remainingDistanceInMeters: number,
  traveledDistanceInMeters: number,
  updatedAt: string,
};

export type GeneratedRoute = {
  __typename?: "GeneratedRoute",
  createdAt: string,
  durationInSeconds: number,
  externalRouteId: string,
  landingHub: Hub,
  landingHubId: string,
  launchHub: Hub,
  launchHubId: string,
  predefinedRouteId: string,
  totalDistanceInMeters: number,
  updatedAt: string,
  waypoints:  Array<Position >,
};

export type Hub = {
  __typename?: "Hub",
  active: boolean,
  address?: string | null,
  createdAt: string,
  id: string,
  name: string,
  position: Position,
  updatedAt: string,
};

export type MissionState = {
  __typename?: "MissionState",
  updatedAt: string,
  value: MissionStateValue,
};

export enum MissionStateValue {
  ACTIVE = "ACTIVE",
  ASSIGNED = "ASSIGNED",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  PAUSED = "PAUSED",
  RESET = "RESET",
}


export type AssignMissionInput = {
  predefinedRouteId: string,
  vin: string,
};

export type CancelMissionInput = {
  missionId: string,
};

export type ContinueStreamInput = {
  cameraID?: string | null,
  vin: string,
};

export type ContinueVideoStream = {
  __typename?: "ContinueVideoStream",
  cameraID?: string | null,
  vin: string,
};

export type CreateHubInput = {
  active: boolean,
  name: string,
  position: PositionInput,
};

export type PositionInput = {
  altitude?: number | null,
  heading?: number | null,
  latitude?: number | null,
  longitude?: number | null,
};

export type ExceptionRequestInput = {
  exceptionEnd?: string | null,
  exceptionSegment: ExceptionSegmentInput,
  exceptionStart: string,
  exceptionType: RoadSegmentRestrictionCause,
};

export type ExceptionSegmentInput = {
  direction: RoadSegmentDirection,
  from: number,
  to: number,
};

export enum RoadSegmentDirection {
  MAIN = "MAIN",
  OPPOSITE = "OPPOSITE",
}


export enum RoadSegmentRestrictionCause {
  ACCIDENT = "ACCIDENT",
  CONGESTION = "CONGESTION",
  MANUAL_INTERVENTION = "MANUAL_INTERVENTION",
  OBSTRUCTION = "OBSTRUCTION",
  ROADWORK = "ROADWORK",
  ROAD_CLOSURE = "ROAD_CLOSURE",
}


export type ExceptionResponse = {
  __typename?: "ExceptionResponse",
  createdAt: string,
  exceptionEnd?: string | null,
  exceptionSegment: ExceptionSegment,
  exceptionStart: string,
  exceptionType: RoadSegmentRestrictionCause,
  id: string,
  status: ExceptionRequestStatus,
};

export type ExceptionSegment = {
  __typename?: "ExceptionSegment",
  direction: RoadSegmentDirection,
  from: number,
  to: number,
};

export enum ExceptionRequestStatus {
  DENIED = "DENIED",
  ERROR = "ERROR",
  GRANTED = "GRANTED",
  PENDING = "PENDING",
}


export type ResetMissionInput = {
  missionId: string,
};

export type VehicleCommandInput = {
  command: Command,
  vin: string,
};

export enum Command {
  MRM = "MRM",
  RELEASE = "RELEASE",
  START_MISSION = "START_MISSION",
}


export type CommandResponse = {
  __typename?: "CommandResponse",
  command: Command,
  errorMessage?: string | null,
  invocationId: string,
  isSuccess: boolean,
  receivedByVehicleAt: string,
  sentToVehicleAt: string,
  vin: string,
};

export type UpdateHubInput = {
  active?: boolean | null,
  id: string,
  name: string,
  position?: PositionInput | null,
};

export type UpdatedMissionInput = {
  actualArrivalTime?: string | null,
  actualStartTime?: string | null,
  createdAt: string,
  description?: string | null,
  events?: Array< MissionEventInput > | null,
  id: string,
  name: string,
  plannedArrivalTime?: string | null,
  progressDetails?: MissionProgressDetailsInput | null,
  route?: UpdatedGeneratedRouteInput | null,
  state: MissionStateInput,
  updatedAt: string,
  vin: string,
};

export type MissionEventInput = {
  createdAt: string,
  position: PositionInput,
  type: MissionEventType,
};

export type MissionProgressDetailsInput = {
  eta: string,
  remainingDistanceInMeters: number,
  traveledDistanceInMeters: number,
  updatedAt: string,
};

export type UpdatedGeneratedRouteInput = {
  createdAt: string,
  durationInSeconds: number,
  externalRouteId: string,
  landingHubId: string,
  launchHubId: string,
  predefinedRouteId: string,
  totalDistanceInMeters: number,
  updatedAt: string,
  waypoints: Array< PositionInput >,
};

export type MissionStateInput = {
  updatedAt: string,
  value: MissionStateValue,
};

export type UpdatedRoadSegmentStatusInput = {
  createdAt: string,
  id: string,
  reportedFromSourceAt: string,
  restrictionCauses: Array< RoadSegmentRestrictionCause >,
  restrictionEnd?: string | null,
  restrictionStart: string,
  segment: RoadSegmentInput,
  source: string,
  updatedAt: string,
};

export type RoadSegmentInput = {
  direction: RoadSegmentDirection,
  from: number,
  to: number,
  waypoints: Array< PositionInput >,
};

export type RoadSegmentStatus = {
  __typename?: "RoadSegmentStatus",
  createdAt: string,
  id: string,
  reportedFromSourceAt: string,
  restrictionCauses: Array< RoadSegmentRestrictionCause >,
  restrictionEnd?: string | null,
  restrictionStart: string,
  segment: RoadSegment,
  source: string,
  updatedAt: string,
};

export type RoadSegment = {
  __typename?: "RoadSegment",
  direction: RoadSegmentDirection,
  from: number,
  to: number,
  waypoints:  Array<Position >,
};

export type UpdatedVehicleInput = {
  activeMissionId?: string | null,
  adsState: AdsState,
  cameras: Array< CameraInput >,
  connectivityStatus?: ConnectivityStatusInput | null,
  createdAt: string,
  customerId?: string | null,
  deviations: Array< DeviationInput >,
  drivingState: DrivingState,
  externalId: string,
  latestVehicleUpdate?: string | null,
  licensePlate?: string | null,
  mrmState: MRMStateInput,
  name?: string | null,
  owners: Array< OwnerInput >,
  position?: PositionInput | null,
  speedInKmh?: number | null,
  speedInMs?: number | null,
  updatedAt: string,
  vin: string,
  weightInKg?: number | null,
};

export enum AdsState {
  AUTONOMOUS = "AUTONOMOUS",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  SHADOW = "SHADOW",
  UNKNOWN = "UNKNOWN",
}


export type CameraInput = {
  id?: string | null,
  name: string,
};

export type ConnectivityStatusInput = {
  description?: string | null,
  expectedDisconnect?: boolean | null,
  latency?: number | null,
  reason?: Reason | null,
  state?: ConnectivityState | null,
};

export enum Reason {
  EXPECTED = "EXPECTED",
  UNEXPECTED = "UNEXPECTED",
  UNKNOWN = "UNKNOWN",
}


export enum ConnectivityState {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  UNKNOWN = "UNKNOWN",
}


export type DeviationInput = {
  category: string,
  description: string,
  id: string,
  isBlocking: boolean,
  origin: string,
  sentAt: string,
  severity: Severity,
};

export enum Severity {
  ERROR = "ERROR",
  WARNING = "WARNING",
}


export enum DrivingState {
  DRIVING = "DRIVING",
  IDLE = "IDLE",
  PARKED = "PARKED",
  REVERSING = "REVERSING",
  UNKNOWN = "UNKNOWN",
}


export type MRMStateInput = {
  updatedAt: string,
  value: MRMStateValue,
};

export enum MRMStateValue {
  EXECUTING_MRM = "EXECUTING_MRM",
  MRC = "MRC",
  NONE = "NONE",
  RELEASING_MRC = "RELEASING_MRC",
}


export type OwnerInput = {
  email: string,
  fullName: string,
  id: string,
  phoneNumber: string,
  position: string,
};

export type Vehicle = {
  __typename?: "Vehicle",
  activeMissionId?: string | null,
  adsState: AdsState,
  cameras:  Array<Camera >,
  connectivityStatus?: ConnectivityStatus | null,
  createdAt: string,
  customerId?: string | null,
  deviations:  Array<Deviation >,
  drivingState: DrivingState,
  externalId: string,
  latestVehicleUpdate?: string | null,
  licensePlate?: string | null,
  mrmState: MRMState,
  name?: string | null,
  owners:  Array<Owner >,
  position?: Position | null,
  speedInKmh?: number | null,
  speedInMs?: number | null,
  updatedAt: string,
  vin: string,
  weightInKg?: number | null,
};

export type Camera = {
  __typename?: "Camera",
  id?: string | null,
  name: string,
};

export type ConnectivityStatus = {
  __typename?: "ConnectivityStatus",
  description?: string | null,
  expectedDisconnect?: boolean | null,
  latency?: number | null,
  reason?: Reason | null,
  state?: ConnectivityState | null,
};

export type Deviation = {
  __typename?: "Deviation",
  category: string,
  description: string,
  id: string,
  isBlocking: boolean,
  origin: string,
  sentAt: string,
  severity: Severity,
};

export type MRMState = {
  __typename?: "MRMState",
  updatedAt: string,
  value: MRMStateValue,
};

export type Owner = {
  __typename?: "Owner",
  email: string,
  fullName: string,
  id: string,
  phoneNumber: string,
  position: string,
};

export type UpdatedVideoStreamingErrorInput = {
  cameraID?: string | null,
  errorCode: VideoStreamingErrorCode,
  errorMessage?: string | null,
  vin: string,
};

export enum VideoStreamingErrorCode {
  CAMERA_DOES_NOT_EXIST = "CAMERA_DOES_NOT_EXIST",
  STREAM_NOT_RUNNING = "STREAM_NOT_RUNNING",
  UNEXPECTED_END_OF_STREAM = "UNEXPECTED_END_OF_STREAM",
  UNKNOWN_STREAM_ERROR = "UNKNOWN_STREAM_ERROR",
}


export type VideoStreamingError = {
  __typename?: "VideoStreamingError",
  cameraID?: string | null,
  errorCode: VideoStreamingErrorCode,
  errorMessage?: string | null,
  vin: string,
};

export type CameraImage = {
  __typename?: "CameraImage",
  timestamp: string,
  value: string,
};

export type HealthStatusInformation = {
  __typename?: "HealthStatusInformation",
  status: HealthStatus,
};

export enum HealthStatus {
  ERROR = "ERROR",
  OK = "OK",
}


export type VideoStream = {
  __typename?: "VideoStream",
  cameraID?: string | null,
  streamingURL: string,
  vin: string,
};

export type Route = {
  __typename?: "Route",
  averageDuration: number,
  createdAt: string,
  distance: number,
  id: string,
  landingHub: Hub,
  launchHub: Hub,
  updatedAt: string,
  waypoints:  Array<Position >,
};

export type ArchiveMissionMutationVariables = {
  input: ArchiveMissionInput,
};

export type ArchiveMissionMutation = {
  archiveMission?:  {
    __typename?: "Mission",
    actualArrivalTime?: string | null,
    actualStartTime?: string | null,
    createdAt: string,
    description?: string | null,
    events?:  Array< {
      __typename?: "MissionEvent",
      createdAt: string,
      position:  {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      },
      type: MissionEventType,
    } > | null,
    id: string,
    name: string,
    plannedArrivalTime?: string | null,
    progressDetails?:  {
      __typename?: "MissionProgressDetails",
      eta: string,
      remainingDistanceInMeters: number,
      traveledDistanceInMeters: number,
      updatedAt: string,
    } | null,
    route?:  {
      __typename?: "GeneratedRoute",
      createdAt: string,
      durationInSeconds: number,
      externalRouteId: string,
      landingHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      landingHubId: string,
      launchHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      launchHubId: string,
      predefinedRouteId: string,
      totalDistanceInMeters: number,
      updatedAt: string,
      waypoints:  Array< {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      } >,
    } | null,
    state:  {
      __typename?: "MissionState",
      updatedAt: string,
      value: MissionStateValue,
    },
    updatedAt: string,
    vin: string,
  } | null,
};

export type AssignMissionMutationVariables = {
  input: AssignMissionInput,
};

export type AssignMissionMutation = {
  assignMission:  {
    __typename?: "Mission",
    actualArrivalTime?: string | null,
    actualStartTime?: string | null,
    createdAt: string,
    description?: string | null,
    events?:  Array< {
      __typename?: "MissionEvent",
      createdAt: string,
      position:  {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      },
      type: MissionEventType,
    } > | null,
    id: string,
    name: string,
    plannedArrivalTime?: string | null,
    progressDetails?:  {
      __typename?: "MissionProgressDetails",
      eta: string,
      remainingDistanceInMeters: number,
      traveledDistanceInMeters: number,
      updatedAt: string,
    } | null,
    route?:  {
      __typename?: "GeneratedRoute",
      createdAt: string,
      durationInSeconds: number,
      externalRouteId: string,
      landingHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      landingHubId: string,
      launchHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      launchHubId: string,
      predefinedRouteId: string,
      totalDistanceInMeters: number,
      updatedAt: string,
      waypoints:  Array< {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      } >,
    } | null,
    state:  {
      __typename?: "MissionState",
      updatedAt: string,
      value: MissionStateValue,
    },
    updatedAt: string,
    vin: string,
  },
};

export type CancelMissionMutationVariables = {
  input: CancelMissionInput,
};

export type CancelMissionMutation = {
  cancelMission?:  {
    __typename?: "Mission",
    actualArrivalTime?: string | null,
    actualStartTime?: string | null,
    createdAt: string,
    description?: string | null,
    events?:  Array< {
      __typename?: "MissionEvent",
      createdAt: string,
      position:  {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      },
      type: MissionEventType,
    } > | null,
    id: string,
    name: string,
    plannedArrivalTime?: string | null,
    progressDetails?:  {
      __typename?: "MissionProgressDetails",
      eta: string,
      remainingDistanceInMeters: number,
      traveledDistanceInMeters: number,
      updatedAt: string,
    } | null,
    route?:  {
      __typename?: "GeneratedRoute",
      createdAt: string,
      durationInSeconds: number,
      externalRouteId: string,
      landingHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      landingHubId: string,
      launchHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      launchHubId: string,
      predefinedRouteId: string,
      totalDistanceInMeters: number,
      updatedAt: string,
      waypoints:  Array< {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      } >,
    } | null,
    state:  {
      __typename?: "MissionState",
      updatedAt: string,
      value: MissionStateValue,
    },
    updatedAt: string,
    vin: string,
  } | null,
};

export type ContinueStreamMutationVariables = {
  input: ContinueStreamInput,
};

export type ContinueStreamMutation = {
  continueStream?:  {
    __typename?: "ContinueVideoStream",
    cameraID?: string | null,
    vin: string,
  } | null,
};

export type CreateHubMutationVariables = {
  input: CreateHubInput,
};

export type CreateHubMutation = {
  createHub?:  {
    __typename?: "Hub",
    active: boolean,
    address?: string | null,
    createdAt: string,
    id: string,
    name: string,
    position:  {
      __typename?: "Position",
      altitude?: number | null,
      heading?: number | null,
      latitude?: number | null,
      longitude?: number | null,
    },
    updatedAt: string,
  } | null,
};

export type RequestExceptionMutationVariables = {
  input: ExceptionRequestInput,
};

export type RequestExceptionMutation = {
  requestException:  {
    __typename?: "ExceptionResponse",
    createdAt: string,
    exceptionEnd?: string | null,
    exceptionSegment:  {
      __typename?: "ExceptionSegment",
      direction: RoadSegmentDirection,
      from: number,
      to: number,
    },
    exceptionStart: string,
    exceptionType: RoadSegmentRestrictionCause,
    id: string,
    status: ExceptionRequestStatus,
  },
};

export type ResetMissionMutationVariables = {
  input: ResetMissionInput,
};

export type ResetMissionMutation = {
  resetMission?:  {
    __typename?: "Mission",
    actualArrivalTime?: string | null,
    actualStartTime?: string | null,
    createdAt: string,
    description?: string | null,
    events?:  Array< {
      __typename?: "MissionEvent",
      createdAt: string,
      position:  {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      },
      type: MissionEventType,
    } > | null,
    id: string,
    name: string,
    plannedArrivalTime?: string | null,
    progressDetails?:  {
      __typename?: "MissionProgressDetails",
      eta: string,
      remainingDistanceInMeters: number,
      traveledDistanceInMeters: number,
      updatedAt: string,
    } | null,
    route?:  {
      __typename?: "GeneratedRoute",
      createdAt: string,
      durationInSeconds: number,
      externalRouteId: string,
      landingHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      landingHubId: string,
      launchHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      launchHubId: string,
      predefinedRouteId: string,
      totalDistanceInMeters: number,
      updatedAt: string,
      waypoints:  Array< {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      } >,
    } | null,
    state:  {
      __typename?: "MissionState",
      updatedAt: string,
      value: MissionStateValue,
    },
    updatedAt: string,
    vin: string,
  } | null,
};

export type TriggerVehicleCommandMutationVariables = {
  input: VehicleCommandInput,
};

export type TriggerVehicleCommandMutation = {
  triggerVehicleCommand:  {
    __typename?: "CommandResponse",
    command: Command,
    errorMessage?: string | null,
    invocationId: string,
    isSuccess: boolean,
    receivedByVehicleAt: string,
    sentToVehicleAt: string,
    vin: string,
  },
};

export type UpdateHubMutationVariables = {
  input: UpdateHubInput,
};

export type UpdateHubMutation = {
  updateHub?:  {
    __typename?: "Hub",
    active: boolean,
    address?: string | null,
    createdAt: string,
    id: string,
    name: string,
    position:  {
      __typename?: "Position",
      altitude?: number | null,
      heading?: number | null,
      latitude?: number | null,
      longitude?: number | null,
    },
    updatedAt: string,
  } | null,
};

export type UpdatedMissionMutationVariables = {
  input: UpdatedMissionInput,
};

export type UpdatedMissionMutation = {
  updatedMission?:  {
    __typename?: "Mission",
    actualArrivalTime?: string | null,
    actualStartTime?: string | null,
    createdAt: string,
    description?: string | null,
    events?:  Array< {
      __typename?: "MissionEvent",
      createdAt: string,
      position:  {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      },
      type: MissionEventType,
    } > | null,
    id: string,
    name: string,
    plannedArrivalTime?: string | null,
    progressDetails?:  {
      __typename?: "MissionProgressDetails",
      eta: string,
      remainingDistanceInMeters: number,
      traveledDistanceInMeters: number,
      updatedAt: string,
    } | null,
    route?:  {
      __typename?: "GeneratedRoute",
      createdAt: string,
      durationInSeconds: number,
      externalRouteId: string,
      landingHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      landingHubId: string,
      launchHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      launchHubId: string,
      predefinedRouteId: string,
      totalDistanceInMeters: number,
      updatedAt: string,
      waypoints:  Array< {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      } >,
    } | null,
    state:  {
      __typename?: "MissionState",
      updatedAt: string,
      value: MissionStateValue,
    },
    updatedAt: string,
    vin: string,
  } | null,
};

export type UpdatedRoadSegmentStatusMutationVariables = {
  input?: UpdatedRoadSegmentStatusInput | null,
};

export type UpdatedRoadSegmentStatusMutation = {
  updatedRoadSegmentStatus?:  {
    __typename?: "RoadSegmentStatus",
    createdAt: string,
    id: string,
    reportedFromSourceAt: string,
    restrictionCauses: Array< RoadSegmentRestrictionCause >,
    restrictionEnd?: string | null,
    restrictionStart: string,
    segment:  {
      __typename?: "RoadSegment",
      direction: RoadSegmentDirection,
      from: number,
      to: number,
      waypoints:  Array< {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      } >,
    },
    source: string,
    updatedAt: string,
  } | null,
};

export type UpdatedVehicleMutationVariables = {
  input?: UpdatedVehicleInput | null,
};

export type UpdatedVehicleMutation = {
  updatedVehicle?:  {
    __typename?: "Vehicle",
    activeMissionId?: string | null,
    adsState: AdsState,
    cameras:  Array< {
      __typename?: "Camera",
      id?: string | null,
      name: string,
    } >,
    connectivityStatus?:  {
      __typename?: "ConnectivityStatus",
      description?: string | null,
      expectedDisconnect?: boolean | null,
      latency?: number | null,
      reason?: Reason | null,
      state?: ConnectivityState | null,
    } | null,
    createdAt: string,
    customerId?: string | null,
    deviations:  Array< {
      __typename?: "Deviation",
      category: string,
      description: string,
      id: string,
      isBlocking: boolean,
      origin: string,
      sentAt: string,
      severity: Severity,
    } >,
    drivingState: DrivingState,
    externalId: string,
    latestVehicleUpdate?: string | null,
    licensePlate?: string | null,
    mrmState:  {
      __typename?: "MRMState",
      updatedAt: string,
      value: MRMStateValue,
    },
    name?: string | null,
    owners:  Array< {
      __typename?: "Owner",
      email: string,
      fullName: string,
      id: string,
      phoneNumber: string,
      position: string,
    } >,
    position?:  {
      __typename?: "Position",
      altitude?: number | null,
      heading?: number | null,
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    speedInKmh?: number | null,
    speedInMs?: number | null,
    updatedAt: string,
    vin: string,
    weightInKg?: number | null,
  } | null,
};

export type UpdatedVideoStreamingErrorMutationVariables = {
  input: UpdatedVideoStreamingErrorInput,
};

export type UpdatedVideoStreamingErrorMutation = {
  updatedVideoStreamingError?:  {
    __typename?: "VideoStreamingError",
    cameraID?: string | null,
    errorCode: VideoStreamingErrorCode,
    errorMessage?: string | null,
    vin: string,
  } | null,
};

export type GetCameraImageByVINQueryVariables = {
  cameraID?: string | null,
  vin: string,
};

export type GetCameraImageByVINQuery = {
  getCameraImageByVIN?:  {
    __typename?: "CameraImage",
    timestamp: string,
    value: string,
  } | null,
};

export type GetHealthStatusQueryVariables = {
};

export type GetHealthStatusQuery = {
  getHealthStatus:  {
    __typename?: "HealthStatusInformation",
    status: HealthStatus,
  },
};

export type GetHubQueryVariables = {
  id: string,
};

export type GetHubQuery = {
  getHub?:  {
    __typename?: "Hub",
    active: boolean,
    address?: string | null,
    createdAt: string,
    id: string,
    name: string,
    position:  {
      __typename?: "Position",
      altitude?: number | null,
      heading?: number | null,
      latitude?: number | null,
      longitude?: number | null,
    },
    updatedAt: string,
  } | null,
};

export type GetMissionQueryVariables = {
  id: string,
};

export type GetMissionQuery = {
  getMission?:  {
    __typename?: "Mission",
    actualArrivalTime?: string | null,
    actualStartTime?: string | null,
    createdAt: string,
    description?: string | null,
    events?:  Array< {
      __typename?: "MissionEvent",
      createdAt: string,
      position:  {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      },
      type: MissionEventType,
    } > | null,
    id: string,
    name: string,
    plannedArrivalTime?: string | null,
    progressDetails?:  {
      __typename?: "MissionProgressDetails",
      eta: string,
      remainingDistanceInMeters: number,
      traveledDistanceInMeters: number,
      updatedAt: string,
    } | null,
    route?:  {
      __typename?: "GeneratedRoute",
      createdAt: string,
      durationInSeconds: number,
      externalRouteId: string,
      landingHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      landingHubId: string,
      launchHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      launchHubId: string,
      predefinedRouteId: string,
      totalDistanceInMeters: number,
      updatedAt: string,
      waypoints:  Array< {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      } >,
    } | null,
    state:  {
      __typename?: "MissionState",
      updatedAt: string,
      value: MissionStateValue,
    },
    updatedAt: string,
    vin: string,
  } | null,
};

export type GetVehicleByVINQueryVariables = {
  vin: string,
};

export type GetVehicleByVINQuery = {
  getVehicleByVIN?:  {
    __typename?: "Vehicle",
    activeMissionId?: string | null,
    adsState: AdsState,
    cameras:  Array< {
      __typename?: "Camera",
      id?: string | null,
      name: string,
    } >,
    connectivityStatus?:  {
      __typename?: "ConnectivityStatus",
      description?: string | null,
      expectedDisconnect?: boolean | null,
      latency?: number | null,
      reason?: Reason | null,
      state?: ConnectivityState | null,
    } | null,
    createdAt: string,
    customerId?: string | null,
    deviations:  Array< {
      __typename?: "Deviation",
      category: string,
      description: string,
      id: string,
      isBlocking: boolean,
      origin: string,
      sentAt: string,
      severity: Severity,
    } >,
    drivingState: DrivingState,
    externalId: string,
    latestVehicleUpdate?: string | null,
    licensePlate?: string | null,
    mrmState:  {
      __typename?: "MRMState",
      updatedAt: string,
      value: MRMStateValue,
    },
    name?: string | null,
    owners:  Array< {
      __typename?: "Owner",
      email: string,
      fullName: string,
      id: string,
      phoneNumber: string,
      position: string,
    } >,
    position?:  {
      __typename?: "Position",
      altitude?: number | null,
      heading?: number | null,
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    speedInKmh?: number | null,
    speedInMs?: number | null,
    updatedAt: string,
    vin: string,
    weightInKg?: number | null,
  } | null,
};

export type GetVideoStreamingURLQueryVariables = {
  cameraID?: string | null,
  vin: string,
};

export type GetVideoStreamingURLQuery = {
  getVideoStreamingURL?:  {
    __typename?: "VideoStream",
    cameraID?: string | null,
    streamingURL: string,
    vin: string,
  } | null,
};

export type ListExceptionsQueryVariables = {
};

export type ListExceptionsQuery = {
  listExceptions:  Array< {
    __typename?: "ExceptionResponse",
    createdAt: string,
    exceptionEnd?: string | null,
    exceptionSegment:  {
      __typename?: "ExceptionSegment",
      direction: RoadSegmentDirection,
      from: number,
      to: number,
    },
    exceptionStart: string,
    exceptionType: RoadSegmentRestrictionCause,
    id: string,
    status: ExceptionRequestStatus,
  } >,
};

export type ListHubsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHubsQuery = {
  listHubs:  Array< {
    __typename?: "Hub",
    active: boolean,
    address?: string | null,
    createdAt: string,
    id: string,
    name: string,
    position:  {
      __typename?: "Position",
      altitude?: number | null,
      heading?: number | null,
      latitude?: number | null,
      longitude?: number | null,
    },
    updatedAt: string,
  } >,
};

export type ListRoadSegmentStatusQueryVariables = {
};

export type ListRoadSegmentStatusQuery = {
  listRoadSegmentStatus:  Array< {
    __typename?: "RoadSegmentStatus",
    createdAt: string,
    id: string,
    reportedFromSourceAt: string,
    restrictionCauses: Array< RoadSegmentRestrictionCause >,
    restrictionEnd?: string | null,
    restrictionStart: string,
    segment:  {
      __typename?: "RoadSegment",
      direction: RoadSegmentDirection,
      from: number,
      to: number,
      waypoints:  Array< {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      } >,
    },
    source: string,
    updatedAt: string,
  } >,
};

export type ListRoutesQueryVariables = {
};

export type ListRoutesQuery = {
  listRoutes:  Array< {
    __typename?: "Route",
    averageDuration: number,
    createdAt: string,
    distance: number,
    id: string,
    landingHub:  {
      __typename?: "Hub",
      active: boolean,
      address?: string | null,
      createdAt: string,
      id: string,
      name: string,
      position:  {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      },
      updatedAt: string,
    },
    launchHub:  {
      __typename?: "Hub",
      active: boolean,
      address?: string | null,
      createdAt: string,
      id: string,
      name: string,
      position:  {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      },
      updatedAt: string,
    },
    updatedAt: string,
    waypoints:  Array< {
      __typename?: "Position",
      altitude?: number | null,
      heading?: number | null,
      latitude?: number | null,
      longitude?: number | null,
    } >,
  } >,
};

export type ListVehiclesQueryVariables = {
};

export type ListVehiclesQuery = {
  listVehicles:  Array< {
    __typename?: "Vehicle",
    activeMissionId?: string | null,
    adsState: AdsState,
    cameras:  Array< {
      __typename?: "Camera",
      id?: string | null,
      name: string,
    } >,
    connectivityStatus?:  {
      __typename?: "ConnectivityStatus",
      description?: string | null,
      expectedDisconnect?: boolean | null,
      latency?: number | null,
      reason?: Reason | null,
      state?: ConnectivityState | null,
    } | null,
    createdAt: string,
    customerId?: string | null,
    deviations:  Array< {
      __typename?: "Deviation",
      category: string,
      description: string,
      id: string,
      isBlocking: boolean,
      origin: string,
      sentAt: string,
      severity: Severity,
    } >,
    drivingState: DrivingState,
    externalId: string,
    latestVehicleUpdate?: string | null,
    licensePlate?: string | null,
    mrmState:  {
      __typename?: "MRMState",
      updatedAt: string,
      value: MRMStateValue,
    },
    name?: string | null,
    owners:  Array< {
      __typename?: "Owner",
      email: string,
      fullName: string,
      id: string,
      phoneNumber: string,
      position: string,
    } >,
    position?:  {
      __typename?: "Position",
      altitude?: number | null,
      heading?: number | null,
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    speedInKmh?: number | null,
    speedInMs?: number | null,
    updatedAt: string,
    vin: string,
    weightInKg?: number | null,
  } >,
};

export type OnUpdateHubSubscriptionVariables = {
};

export type OnUpdateHubSubscription = {
  onUpdateHub?:  {
    __typename?: "Hub",
    active: boolean,
    address?: string | null,
    createdAt: string,
    id: string,
    name: string,
    position:  {
      __typename?: "Position",
      altitude?: number | null,
      heading?: number | null,
      latitude?: number | null,
      longitude?: number | null,
    },
    updatedAt: string,
  } | null,
};

export type OnUpdateMissionSubscriptionVariables = {
  id: string,
};

export type OnUpdateMissionSubscription = {
  onUpdateMission?:  {
    __typename?: "Mission",
    actualArrivalTime?: string | null,
    actualStartTime?: string | null,
    createdAt: string,
    description?: string | null,
    events?:  Array< {
      __typename?: "MissionEvent",
      createdAt: string,
      position:  {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      },
      type: MissionEventType,
    } > | null,
    id: string,
    name: string,
    plannedArrivalTime?: string | null,
    progressDetails?:  {
      __typename?: "MissionProgressDetails",
      eta: string,
      remainingDistanceInMeters: number,
      traveledDistanceInMeters: number,
      updatedAt: string,
    } | null,
    route?:  {
      __typename?: "GeneratedRoute",
      createdAt: string,
      durationInSeconds: number,
      externalRouteId: string,
      landingHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      landingHubId: string,
      launchHub:  {
        __typename?: "Hub",
        active: boolean,
        address?: string | null,
        createdAt: string,
        id: string,
        name: string,
        position:  {
          __typename?: "Position",
          altitude?: number | null,
          heading?: number | null,
          latitude?: number | null,
          longitude?: number | null,
        },
        updatedAt: string,
      },
      launchHubId: string,
      predefinedRouteId: string,
      totalDistanceInMeters: number,
      updatedAt: string,
      waypoints:  Array< {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      } >,
    } | null,
    state:  {
      __typename?: "MissionState",
      updatedAt: string,
      value: MissionStateValue,
    },
    updatedAt: string,
    vin: string,
  } | null,
};

export type OnUpdateRoadSegmentStatusSubscriptionVariables = {
};

export type OnUpdateRoadSegmentStatusSubscription = {
  onUpdateRoadSegmentStatus?:  {
    __typename?: "RoadSegmentStatus",
    createdAt: string,
    id: string,
    reportedFromSourceAt: string,
    restrictionCauses: Array< RoadSegmentRestrictionCause >,
    restrictionEnd?: string | null,
    restrictionStart: string,
    segment:  {
      __typename?: "RoadSegment",
      direction: RoadSegmentDirection,
      from: number,
      to: number,
      waypoints:  Array< {
        __typename?: "Position",
        altitude?: number | null,
        heading?: number | null,
        latitude?: number | null,
        longitude?: number | null,
      } >,
    },
    source: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVehicleSubscriptionVariables = {
};

export type OnUpdateVehicleSubscription = {
  onUpdateVehicle?:  {
    __typename?: "Vehicle",
    activeMissionId?: string | null,
    adsState: AdsState,
    cameras:  Array< {
      __typename?: "Camera",
      id?: string | null,
      name: string,
    } >,
    connectivityStatus?:  {
      __typename?: "ConnectivityStatus",
      description?: string | null,
      expectedDisconnect?: boolean | null,
      latency?: number | null,
      reason?: Reason | null,
      state?: ConnectivityState | null,
    } | null,
    createdAt: string,
    customerId?: string | null,
    deviations:  Array< {
      __typename?: "Deviation",
      category: string,
      description: string,
      id: string,
      isBlocking: boolean,
      origin: string,
      sentAt: string,
      severity: Severity,
    } >,
    drivingState: DrivingState,
    externalId: string,
    latestVehicleUpdate?: string | null,
    licensePlate?: string | null,
    mrmState:  {
      __typename?: "MRMState",
      updatedAt: string,
      value: MRMStateValue,
    },
    name?: string | null,
    owners:  Array< {
      __typename?: "Owner",
      email: string,
      fullName: string,
      id: string,
      phoneNumber: string,
      position: string,
    } >,
    position?:  {
      __typename?: "Position",
      altitude?: number | null,
      heading?: number | null,
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    speedInKmh?: number | null,
    speedInMs?: number | null,
    updatedAt: string,
    vin: string,
    weightInKg?: number | null,
  } | null,
};

export type OnUpdateVideoStreamingErrorSubscriptionVariables = {
  cameraID?: string | null,
  vin: string,
};

export type OnUpdateVideoStreamingErrorSubscription = {
  onUpdateVideoStreamingError?:  {
    __typename?: "VideoStreamingError",
    cameraID?: string | null,
    errorCode: VideoStreamingErrorCode,
    errorMessage?: string | null,
    vin: string,
  } | null,
};

import ApplicationLayout from 'components/ApplicationLayout'
import AppHeader from 'features/header/AppHeader'
import { Outlet, useLocation } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { DEFAULT_LOCALE } from 'store/i18n/lang'
import { getDisplayMessages, getLocale } from 'store/i18n/langSlice'
import { useAppSelector } from 'store/setup/hooks'
import SystemNotification from 'features/system-health/SystemNotification'
import { useCheckHealthStatus } from 'hooks/useCheckHealthStatus'
import { NotificationsContainer } from '@rio-cloud/rio-uikit'
import { useSelector } from 'react-redux'
import { getIsSystemOK } from 'store/slices/appSlice'

interface AppLayoutProps {
  title?: string
}

const AppLayout = ({ title }: AppLayoutProps) => {
  const userLocale = useAppSelector(getLocale)
  const displayMessages = useAppSelector(getDisplayMessages)
  const { pathname } = useLocation()
  const isSystemOk = useSelector(getIsSystemOK)
  useCheckHealthStatus()

  if (!displayMessages || !userLocale) {
    return null
  }

  const isMonitoringPage = pathname === '/'

  /*
    TODO: move this logic outta this components from the moment we add multiple pages
    const title = isMonitoringPage ? (
      <FormattedMessage id={'intl-msg:control-center.sublink.monitoring'} />
    ) : (
      <FormattedMessage id={'intl-msg:control-center.sublink.vehicle'} />
    )
  */

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      key={userLocale}
      locale={userLocale}
      messages={displayMessages}
    >
      <ApplicationLayout>
        <ApplicationLayout.Header>
          <AppHeader title={title} showAccountMenu={isMonitoringPage} />
          {!isSystemOk && (
            <div className="absolute top-50 z-[999] max-h-[45px] w-full">
              <SystemNotification shouldRender={isSystemOk} />
            </div>
          )}
        </ApplicationLayout.Header>
        {/* UIKit class padding-0 needed for internal css selectors to work */}
        <ApplicationLayout.Body
          forceScrollbar={false}
          enableScrollToTop={false}
          className="overflow-y-hidden"
          innerClassName={`p-0 padding-0 ${!isSystemOk && 'mt-[45px]'}`}
        >
          <NotificationsContainer />
          <div className={`${!isSystemOk ? 'h-[calc(100vh-95px)]' : 'h-full'}`}>
            <Outlet />
          </div>
        </ApplicationLayout.Body>
      </ApplicationLayout>
    </IntlProvider>
  )
}

export default AppLayout

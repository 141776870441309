import { formatDate, formatTime } from '../../helper/time'

interface DateTimeProps {
  date: Date
  className?: string
  separator?: boolean
}

const DateTime = ({ date, className, separator }: DateTimeProps) => (
  <span data-testid="datetime-component" className={`flex items-center gap-5 ${className}`}>
    <span data-testid="datetime-component-time" className={separator ? '' : 'w-40'}>
      {formatTime(date)}
    </span>
    {separator && <span data-testid="datetime-component-separator">-</span>}
    <span data-testid="datetime-component-date" className={separator ? '' : 'w-[90px]'}>
      {formatDate(date)}
    </span>
  </span>
)

export default DateTime

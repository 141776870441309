export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED'
}

interface UserRowStatusProps {
  status: UserStatus
}

const UserRowStatus = ({ status }: UserRowStatusProps) => {
  switch (status) {
    case UserStatus.ACTIVE:
      return (
        <div data-testid="exceptions-status" className="label label-success label-filled w-max">
          ACTIVE
        </div>
      )
    case UserStatus.INVITED:
      return (
        <div data-testid="exceptions-status" className="w-max">
          <div className="label label-default label-filled">INVITED</div>
          <span className="rioglyph rioglyph-send ml-5 text-20 text-primary" />
        </div>
      )
    default:
      return null
  }
}

export default UserRowStatus

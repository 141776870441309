import { fetchAuthSession } from 'aws-amplify/auth'
import { CORRELATION_ID_LABEL } from 'graphql/constants'
import { v4 as uuid } from 'uuid'

export const setHeaders = async () => {
  const { accessToken } = (await fetchAuthSession()).tokens ?? {}
  if (accessToken) {
    return {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      [CORRELATION_ID_LABEL]: uuid()
    }
  }
}

export enum SimulationState {
  UPLOADED = 'UPLOADED',
  READY_TO_START = 'READY_TO_START',
  PREPARE_FOR_DRIVING = 'PREPARE_FOR_DRIVING',
  DRIVING = 'DRIVING',
  EXECUTING_MRM = 'EXECUTING_MRM',
  IN_MRC = 'IN_MRC',
  RELEASING_MRC = 'RELEASING_MRC',
  PREPARE_FOR_COMPLETION = 'PREPARE_FOR_COMPLETION',
  ARRIVED = 'ARRIVED',
  END = 'END'
}

export enum Action {
  INITIALIZE,
  START,
  STOP
}

export interface ScenarioActionsObject {
  icon: string
  action: Action
}

export const scenarioButtonActions = new Map<SimulationState, ScenarioActionsObject>([
  [SimulationState.UPLOADED, { icon: 'rioglyph rioglyph-play', action: Action.INITIALIZE }],
  [
    SimulationState.READY_TO_START,
    { icon: 'rioglyph rioglyph-play text-success', action: Action.START }
  ],
  [SimulationState.PREPARE_FOR_DRIVING, { icon: 'rioglyph rioglyph-pause', action: Action.STOP }],
  [SimulationState.DRIVING, { icon: 'rioglyph rioglyph-pause', action: Action.STOP }],
  [
    SimulationState.EXECUTING_MRM,
    { icon: 'rioglyph rioglyph-tire spinning text-color-danger', action: Action.STOP }
  ],
  [
    SimulationState.IN_MRC,
    { icon: 'rioglyph rioglyph-tire spinning text-color-warning', action: Action.STOP }
  ],
  [
    SimulationState.RELEASING_MRC,
    { icon: 'rioglyph rioglyph-tire spinning btn-block', action: Action.STOP }
  ],
  [
    SimulationState.PREPARE_FOR_COMPLETION,
    { icon: 'rioglyph rioglyph-tire spinning', action: Action.STOP }
  ],
  [SimulationState.ARRIVED, { icon: 'rioglyph rioglyph-tire spinning', action: Action.STOP }],
  [
    SimulationState.END,
    {
      icon: 'rioglyph rioglyph-truck rioglyph-filled text-color-success',
      action: Action.INITIALIZE
    }
  ]
])

import { Sidebar } from '@rio-cloud/rio-uikit'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActiveSidebar,
  clearActiveElements,
  getActiveSidebar,
  getActiveTeam
} from 'store/slices/settingsPageSlice'
import { faker } from '@faker-js/faker'

const TeamsSettingsSidebar = () => {
  const dispatch = useDispatch()
  const activeSidebar = useSelector(getActiveSidebar)
  const activeTeam = useSelector(getActiveTeam)

  const closeSidebar = () => {
    dispatch(clearActiveElements())
  }

  return (
    <Sidebar
      fly
      title={<p className="font-bold text-gray-darkest">Team detail</p>}
      titleClassName="pl-10"
      closed={activeSidebar !== ActiveSidebar.TEAMS}
      onClose={closeSidebar}
    >
      <div className="px-20 text-gray-darkest">
        <h4 className="my-20 font-display font-bold">{activeTeam?.name}</h4>
        <div className="mt-[30px]">
          <p className="border-b border-gray-lighter pb-10 font-bold text-gray-darkest">Users</p>
          <ul className="list-none pl-10">
            {/* TODO: adjust SidebarSection component  */}
            <p className="flex items-start py-10">
              <span className="rioglyph rioglyph-user mr-5 text-18" />
              <p className="leading-none">
                {faker.person.firstName()} {faker.person.lastName()}
              </p>
            </p>
            <p className="flex items-start py-10">
              <span className="rioglyph rioglyph-user mr-5 text-18" />
              <p className="leading-none">
                {faker.person.firstName()} {faker.person.lastName()}
              </p>
            </p>
            <p className="flex items-start py-10">
              <span className="rioglyph rioglyph-user mr-5 text-18" />
              <p className="leading-none">
                {faker.person.firstName()} {faker.person.lastName()}
              </p>
            </p>
            <p className="grid grid-cols-4 gap-25 border-t border-gray-lighter py-10">
              <p className="col-span-1 mr-50 font-bold text-gray-dark">Total</p>
              <p className="col-span-3">3</p>
            </p>
          </ul>
        </div>
      </div>
    </Sidebar>
  )
}

export default TeamsSettingsSidebar

import { PropsWithChildren, useState } from 'react'

interface ContentBoxProps {
  header?: string
}

const ContentBox = ({ header, children }: PropsWithChildren<ContentBoxProps>) => {
  const [open, handleOpen] = useState<boolean>(false)
  return (
    <div className="rounded-xl bg-gray-darkest p-15 shadow-sm">
      <p
        className={`mb-5 flex cursor-pointer items-center justify-between pb-5 font-bold text-white ${!open && 'border-b border-gray-dark'}`}
        onClick={() => handleOpen(!open)}
      >
        {header}
        <span className={`rioglyph rioglyph-chevron-up ${!open && 'rotate-180'}`} />
      </p>
      <div className={open ? 'flex' : 'hidden'}>{children}</div>
    </div>
  )
}

export default ContentBox

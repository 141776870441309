import { Navigate, createBrowserRouter } from 'react-router-dom'
import ErrorBoundary from 'components/ErrorBoundary'
import AppLayout from 'layout/AppLayout'
import ExceptionsLayout from 'layout/ExceptionsLayout'
import SimulationsLayout from 'layout/SimulationsLayout'
import { ROUTES } from './types'
import MainPage from 'pages/MainPage'
import ErrorFallback from 'components/feedback/ErrorFallback'
import ExceptionsPage from 'pages/ExceptionsPage'
import SimulationsPage from 'pages/SimulationsPage'
import SettingsPage from 'pages/SettingsPage'
import SettingsLayout from 'layout/SettingsLayout'

export const router = createBrowserRouter([
  {
    path: ROUTES.DEFAULT,
    errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
    element: <AppLayout title="Monitoring" />,
    children: [
      {
        index: true,
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
        element: <MainPage />
      },
      {
        path: '*',
        element: <Navigate to={ROUTES.DEFAULT} replace />
      },
      {
        path: ROUTES.VEHICLE,
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
        lazy: async () => {
          const { default: NoVehicleSelected } = await import('pages/vehicle/NoVehicleSelected')
          return { Component: NoVehicleSelected }
        }
      },
      {
        path: ROUTES.VEHICLE_BY_VIN,
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
        lazy: async () => {
          const { default: VehiclePage } = await import('pages/vehicle/VehiclePage')
          return { Component: VehiclePage }
        }
      }
    ]
  },
  {
    path: ROUTES.EXCEPTIONS,
    errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
    element: <ExceptionsLayout />,
    children: [
      {
        index: true,
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
        element: <ExceptionsPage />
      }
    ]
  },
  {
    path: ROUTES.SIMULATIONS,
    errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
    element: <SimulationsLayout />,
    children: [
      {
        index: true,
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
        element: <SimulationsPage />
      }
    ]
  },
  {
    path: ROUTES.SETTINGS,
    errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
    element: <SettingsLayout />,
    children: [
      {
        index: true,
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
        element: <SettingsPage />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.DEFAULT} replace />
  }
])

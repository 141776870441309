interface ErrorMessageProps {
  message: string
  onClose?: () => void
}

const ErrorMessage = ({ message, onClose }: ErrorMessageProps) => {
  return (
    <div className="mb-10 flex w-full items-center justify-between bg-danger p-5 text-white">
      <p data-testid="error-message">{message}</p>
      <span
        data-testid="error-message-icon"
        onClick={onClose}
        className="rioglyph rioglyph-remove mx-5 cursor-pointer text-12"
      />
    </div>
  )
}

export default ErrorMessage

import { Sidebar } from '@rio-cloud/rio-uikit'
import Button from 'components/actions/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActiveSidebar,
  clearActiveElements,
  getActiveSidebar,
  getActiveUser
} from 'store/slices/settingsPageSlice'
import SidebarSection, { SidebarSectionItem } from './SidebarSection'
import { faker } from '@faker-js/faker'

const UsersSettingsSidebar = () => {
  const dispatch = useDispatch()
  const activeSidebar = useSelector(getActiveSidebar)
  const activeUser = useSelector(getActiveUser)

  const personalItems: SidebarSectionItem[] = [
    {
      label: 'E-mail',
      element: activeUser?.email
    },
    {
      label: 'Phone',
      element: activeUser?.phone
    },
    {
      label: 'Country',
      element: activeUser?.country
    },
    {
      label: 'City',
      element: activeUser?.city
    }
  ]

  const closeSidebar = () => {
    dispatch(clearActiveElements())
  }

  return (
    <Sidebar
      fly
      title={<p className="font-bold text-gray-darkest">User information</p>}
      titleClassName="pl-10"
      closed={activeSidebar !== ActiveSidebar.USERS}
      onClose={closeSidebar}
      footer={
        <div className="grid grid-cols-7 items-center gap-5">
          <Button variant="outline" style="primary" className="col-span-6 w-full">
            <span className="rioglyph rioglyph-pencil mr-10" />
            Edit
          </Button>
          <Button variant="outline" style="danger" className="flex items-center justify-center">
            <span className="rioglyph rioglyph-off -mr-2" />
          </Button>
        </div>
      }
    >
      <div className="px-20 text-gray-darkest">
        <h4 className="my-20 font-display font-bold">{`${activeUser?.firstName} ${activeUser?.lastName}`}</h4>
        <div className="mt-[30px]">
          <p className="border-b border-gray-lighter pb-10 font-bold text-gray-darkest">Personal</p>
          <div className="mb-25 mt-5">
            <SidebarSection items={personalItems} />
          </div>
        </div>
        <div className="mt-[30px]">
          <p className="border-b border-gray-lighter pb-10 font-bold text-gray-darkest">Users</p>
          <ul className="list-none pl-10">
            {/* TODO: adjust SidebarSection component  */}
            <p className="flex items-start py-10">
              <span className="rioglyph rioglyph-user mr-5 text-18" />
              <p className="leading-none">
                {faker.person.firstName()} {faker.person.lastName()}
              </p>
            </p>
            <p className="flex items-start py-10">
              <span className="rioglyph rioglyph-user mr-5 text-18" />
              <p className="leading-none">
                {faker.person.firstName()} {faker.person.lastName()}
              </p>
            </p>
            <p className="flex items-start py-10">
              <span className="rioglyph rioglyph-user mr-5 text-18" />
              <p className="leading-none">
                {faker.person.firstName()} {faker.person.lastName()}
              </p>
            </p>
            <p className="grid grid-cols-4 gap-25 border-t border-gray-lighter py-10">
              <p className="col-span-1 mr-50 font-bold text-gray-dark">Total</p>
              <p className="col-span-3">3</p>
            </p>
          </ul>
        </div>
      </div>
    </Sidebar>
  )
}

export default UsersSettingsSidebar

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const archiveMission = /* GraphQL */ `mutation ArchiveMission($input: ArchiveMissionInput!) {
  archiveMission(input: $input) {
    actualArrivalTime
    actualStartTime
    createdAt
    description
    events {
      createdAt
      position {
        altitude
        heading
        latitude
        longitude
      }
      type
    }
    id
    name
    plannedArrivalTime
    progressDetails {
      eta
      remainingDistanceInMeters
      traveledDistanceInMeters
      updatedAt
    }
    route {
      createdAt
      durationInSeconds
      externalRouteId
      landingHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      landingHubId
      launchHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      launchHubId
      predefinedRouteId
      totalDistanceInMeters
      updatedAt
      waypoints {
        altitude
        heading
        latitude
        longitude
      }
    }
    state {
      updatedAt
      value
    }
    updatedAt
    vin
  }
}
` as GeneratedMutation<
  APITypes.ArchiveMissionMutationVariables,
  APITypes.ArchiveMissionMutation
>;
export const assignMission = /* GraphQL */ `mutation AssignMission($input: AssignMissionInput!) {
  assignMission(input: $input) {
    actualArrivalTime
    actualStartTime
    createdAt
    description
    events {
      createdAt
      position {
        altitude
        heading
        latitude
        longitude
      }
      type
    }
    id
    name
    plannedArrivalTime
    progressDetails {
      eta
      remainingDistanceInMeters
      traveledDistanceInMeters
      updatedAt
    }
    route {
      createdAt
      durationInSeconds
      externalRouteId
      landingHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      landingHubId
      launchHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      launchHubId
      predefinedRouteId
      totalDistanceInMeters
      updatedAt
      waypoints {
        altitude
        heading
        latitude
        longitude
      }
    }
    state {
      updatedAt
      value
    }
    updatedAt
    vin
  }
}
` as GeneratedMutation<
  APITypes.AssignMissionMutationVariables,
  APITypes.AssignMissionMutation
>;
export const cancelMission = /* GraphQL */ `mutation CancelMission($input: CancelMissionInput!) {
  cancelMission(input: $input) {
    actualArrivalTime
    actualStartTime
    createdAt
    description
    events {
      createdAt
      position {
        altitude
        heading
        latitude
        longitude
      }
      type
    }
    id
    name
    plannedArrivalTime
    progressDetails {
      eta
      remainingDistanceInMeters
      traveledDistanceInMeters
      updatedAt
    }
    route {
      createdAt
      durationInSeconds
      externalRouteId
      landingHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      landingHubId
      launchHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      launchHubId
      predefinedRouteId
      totalDistanceInMeters
      updatedAt
      waypoints {
        altitude
        heading
        latitude
        longitude
      }
    }
    state {
      updatedAt
      value
    }
    updatedAt
    vin
  }
}
` as GeneratedMutation<
  APITypes.CancelMissionMutationVariables,
  APITypes.CancelMissionMutation
>;
export const continueStream = /* GraphQL */ `mutation ContinueStream($input: ContinueStreamInput!) {
  continueStream(input: $input) {
    cameraID
    vin
  }
}
` as GeneratedMutation<
  APITypes.ContinueStreamMutationVariables,
  APITypes.ContinueStreamMutation
>;
export const createHub = /* GraphQL */ `mutation CreateHub($input: CreateHubInput!) {
  createHub(input: $input) {
    active
    address
    createdAt
    id
    name
    position {
      altitude
      heading
      latitude
      longitude
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateHubMutationVariables,
  APITypes.CreateHubMutation
>;
export const requestException = /* GraphQL */ `mutation RequestException($input: ExceptionRequestInput!) {
  requestException(input: $input) {
    createdAt
    exceptionEnd
    exceptionSegment {
      direction
      from
      to
    }
    exceptionStart
    exceptionType
    id
    status
  }
}
` as GeneratedMutation<
  APITypes.RequestExceptionMutationVariables,
  APITypes.RequestExceptionMutation
>;
export const resetMission = /* GraphQL */ `mutation ResetMission($input: ResetMissionInput!) {
  resetMission(input: $input) {
    actualArrivalTime
    actualStartTime
    createdAt
    description
    events {
      createdAt
      position {
        altitude
        heading
        latitude
        longitude
      }
      type
    }
    id
    name
    plannedArrivalTime
    progressDetails {
      eta
      remainingDistanceInMeters
      traveledDistanceInMeters
      updatedAt
    }
    route {
      createdAt
      durationInSeconds
      externalRouteId
      landingHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      landingHubId
      launchHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      launchHubId
      predefinedRouteId
      totalDistanceInMeters
      updatedAt
      waypoints {
        altitude
        heading
        latitude
        longitude
      }
    }
    state {
      updatedAt
      value
    }
    updatedAt
    vin
  }
}
` as GeneratedMutation<
  APITypes.ResetMissionMutationVariables,
  APITypes.ResetMissionMutation
>;
export const triggerVehicleCommand = /* GraphQL */ `mutation TriggerVehicleCommand($input: VehicleCommandInput!) {
  triggerVehicleCommand(input: $input) {
    command
    errorMessage
    invocationId
    isSuccess
    receivedByVehicleAt
    sentToVehicleAt
    vin
  }
}
` as GeneratedMutation<
  APITypes.TriggerVehicleCommandMutationVariables,
  APITypes.TriggerVehicleCommandMutation
>;
export const updateHub = /* GraphQL */ `mutation UpdateHub($input: UpdateHubInput!) {
  updateHub(input: $input) {
    active
    address
    createdAt
    id
    name
    position {
      altitude
      heading
      latitude
      longitude
    }
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateHubMutationVariables,
  APITypes.UpdateHubMutation
>;
export const updatedMission = /* GraphQL */ `mutation UpdatedMission($input: UpdatedMissionInput!) {
  updatedMission(input: $input) {
    actualArrivalTime
    actualStartTime
    createdAt
    description
    events {
      createdAt
      position {
        altitude
        heading
        latitude
        longitude
      }
      type
    }
    id
    name
    plannedArrivalTime
    progressDetails {
      eta
      remainingDistanceInMeters
      traveledDistanceInMeters
      updatedAt
    }
    route {
      createdAt
      durationInSeconds
      externalRouteId
      landingHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      landingHubId
      launchHub {
        active
        address
        createdAt
        id
        name
        position {
          altitude
          heading
          latitude
          longitude
        }
        updatedAt
      }
      launchHubId
      predefinedRouteId
      totalDistanceInMeters
      updatedAt
      waypoints {
        altitude
        heading
        latitude
        longitude
      }
    }
    state {
      updatedAt
      value
    }
    updatedAt
    vin
  }
}
` as GeneratedMutation<
  APITypes.UpdatedMissionMutationVariables,
  APITypes.UpdatedMissionMutation
>;
export const updatedRoadSegmentStatus = /* GraphQL */ `mutation UpdatedRoadSegmentStatus($input: UpdatedRoadSegmentStatusInput) {
  updatedRoadSegmentStatus(input: $input) {
    createdAt
    id
    reportedFromSourceAt
    restrictionCauses
    restrictionEnd
    restrictionStart
    segment {
      direction
      from
      to
      waypoints {
        altitude
        heading
        latitude
        longitude
      }
    }
    source
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdatedRoadSegmentStatusMutationVariables,
  APITypes.UpdatedRoadSegmentStatusMutation
>;
export const updatedVehicle = /* GraphQL */ `mutation UpdatedVehicle($input: UpdatedVehicleInput) {
  updatedVehicle(input: $input) {
    activeMissionId
    adsState
    cameras {
      id
      name
    }
    connectivityStatus {
      description
      expectedDisconnect
      latency
      reason
      state
    }
    createdAt
    customerId
    deviations {
      category
      description
      id
      isBlocking
      origin
      sentAt
      severity
    }
    drivingState
    externalId
    latestVehicleUpdate
    licensePlate
    mrmState {
      updatedAt
      value
    }
    name
    owners {
      email
      fullName
      id
      phoneNumber
      position
    }
    position {
      altitude
      heading
      latitude
      longitude
    }
    speedInKmh
    speedInMs
    updatedAt
    vin
    weightInKg
  }
}
` as GeneratedMutation<
  APITypes.UpdatedVehicleMutationVariables,
  APITypes.UpdatedVehicleMutation
>;
export const updatedVideoStreamingError = /* GraphQL */ `mutation UpdatedVideoStreamingError($input: UpdatedVideoStreamingErrorInput!) {
  updatedVideoStreamingError(input: $input) {
    cameraID
    errorCode
    errorMessage
    vin
  }
}
` as GeneratedMutation<
  APITypes.UpdatedVideoStreamingErrorMutationVariables,
  APITypes.UpdatedVideoStreamingErrorMutation
>;

import { useMemo } from 'react'
import { MRMStateValue } from 'API'

const useMRMProcess = (value?: MRMStateValue | null) => {
  const { isPerformingMRM, isMRC, isReleasingMRC } = useMemo(
    () => ({
      isPerformingMRM: value === MRMStateValue.EXECUTING_MRM,
      isMRC: value === MRMStateValue.MRC,
      isReleasingMRC: value === MRMStateValue.RELEASING_MRC
    }),
    [value]
  )

  const isLoading = isPerformingMRM || isReleasingMRC

  if (!value || value === MRMStateValue.NONE) {
    return {
      isMRC: false,
      isPerformingMRM: false,
      isReleasingMRC: false,
      isLoading: false
    }
  }

  return {
    isMRC,
    isPerformingMRM,
    isReleasingMRC,
    isLoading
  }
}

export default useMRMProcess

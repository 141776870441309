import { generateClient } from 'aws-amplify/api'
import { v4 as uuid } from 'uuid'
import { CORRELATION_ID_LABEL } from './constants'

const customHeaders = {
  [CORRELATION_ID_LABEL]: uuid()
}

export const client = generateClient({
  headers: customHeaders
})

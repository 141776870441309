import States from 'components/feedback/States'
import { ReactNode } from 'react'

interface NoContentProps {
  title: string
  body: string
  button: ReactNode
}

const NoContent = ({ title, body, button }: NoContentProps) => {
  return (
    <States
      data-testid="no-settings-table-content"
      icon={
        <span className="rioglyph rioglyph-looking-glass-man mb-20 text-[90px] text-gray-light" />
      }
      title={title}
      body={body}
      button={button}
    />
  )
}

export default NoContent

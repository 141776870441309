import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store/reducers'

// todo: this type should come from schema
export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED'
}

export enum ActiveSidebar {
  USERS = 'USERS',
  TEAMS = 'TEAMS',
  FLEETS = 'FLEETS'
}

// todo: this type should come from schema
export interface User {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  country: string
  city: string
  team: string
  role: string
  status: UserStatus
  created: string
}

export interface Team {
  id: string
  name: string
  numberOfUsers: number
  numberOfFleets: number
  created: string
}

export type ActiveSidebarT =
  | ActiveSidebar.USERS
  | ActiveSidebar.TEAMS
  | ActiveSidebar.FLEETS
  | undefined

export interface SettingsPageState {
  activeSidebar: ActiveSidebarT
  activeUser: undefined | User
  activeTeam: undefined | Team
  activeFleet: undefined | string // string should be replaced by original type - when defined
}

const initialState: SettingsPageState = {
  activeSidebar: undefined,
  activeUser: undefined,
  activeTeam: undefined,
  activeFleet: undefined
}

export const resetMissionSlice = createSlice({
  name: 'settingsPage',
  initialState,
  reducers: {
    setActiveUser: (state: SettingsPageState, action: PayloadAction<User | undefined>) => {
      state.activeSidebar = ActiveSidebar.USERS
      state.activeUser = action.payload
      state.activeTeam = undefined
      state.activeFleet = undefined
    },
    setActiveTeam: (state: SettingsPageState, action: PayloadAction<Team | undefined>) => {
      state.activeSidebar = ActiveSidebar.TEAMS
      state.activeUser = undefined
      state.activeTeam = action.payload
      state.activeFleet = undefined
    },
    clearActiveElements: (state: SettingsPageState) => {
      state.activeSidebar = undefined
      state.activeUser = undefined
      state.activeTeam = undefined
      state.activeFleet = undefined
    }
  }
})

export const { setActiveUser, setActiveTeam, clearActiveElements } = resetMissionSlice.actions

export const getActiveSidebar = (state: RootState) => state.settingsPage.activeSidebar
export const getActiveUser = (state: RootState) => state.settingsPage.activeUser
export const getActiveTeam = (state: RootState) => state.settingsPage.activeTeam

export default resetMissionSlice.reducer

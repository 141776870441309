import { createApi } from '@reduxjs/toolkit/query/react'
import { client } from 'graphql/client'
import { z } from 'zod'
import { getCameraImageByVIN, getVideoStreamingURL, listVehicles } from 'graphql/queries'
import {
  CameraImage,
  ContinueStreamInput,
  ContinueVideoStream,
  GetCameraImageByVINQueryVariables,
  GetVideoStreamingURLQueryVariables,
  OnUpdateVehicleSubscriptionVariables,
  OnUpdateVideoStreamingErrorSubscriptionVariables,
  Vehicle,
  VideoStream,
  VideoStreamingError
} from 'API'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { onUpdateVehicle, onUpdateVideoStreamingError } from '../graphql/subscriptions'
import {
  GenericRecord,
  createVideoStreamingErrorIdentifier,
  denormalizeListWithVin,
  normalizeRecordData
} from './utils'
import {
  CameraImageSchema,
  VideoStreamSchema,
  VideoStreamingErrorSchema,
  ContinueVideoStreamSchema,
  VehicleSchema
} from 'APIzod'
import { errorHandler } from './errors'
import { continueStream } from 'graphql/mutations'

import { registerVehiclesDevTools, registerVideoStreamDevTools } from 'mocks/utils'
/**
 * The reason for the decision to calculate the latency here in the FE instead of in the VAS,
 * was the availability of the information in the FE while avoiding the additional effort
 * needed to extend the vehicle model with the calculated latency property.
 *
 * This should be expected to be calculated in the VAS whenever the latency calculation
 * feature complexity increases.
 */
const getDifferenceInMilliseconds = (
  latestVehicleUpdateDateTime: Date,
  updatedAt: Date
): number => {
  return updatedAt.getTime() - latestVehicleUpdateDateTime.getTime()
}

const calculateLatency = (vehicle: Vehicle) => {
  return vehicle.latestVehicleUpdate !== null && vehicle.latestVehicleUpdate !== undefined
    ? getDifferenceInMilliseconds(
        new Date(vehicle.latestVehicleUpdate),
        new Date(vehicle.updatedAt)
      )
    : undefined
}

const withConnectivityLatency = (vehicle: Vehicle) => ({
  ...vehicle,
  connectivityStatus: {
    ...vehicle.connectivityStatus,
    latency: vehicle.connectivityStatus?.latency ?? calculateLatency(vehicle)
  }
})

const transformDeviationsWithVin = (vehicle: Vehicle) => {
  return {
    ...vehicle,
    deviations: vehicle.deviations
      ? vehicle.deviations.map((_) => ({ ..._, vin: vehicle.vin }))
      : []
  }
}
const mapToVehicles = (vehicleList: Vehicle[]) => {
  return z.array(VehicleSchema()).parse(vehicleList.map(transformDeviationsWithVin))
}

const listVehiclesQueryFn = async () => {
  try {
    const { data } = await client.graphql({ query: listVehicles })
    const vehicles = mapToVehicles(data.listVehicles)
    return {
      data: denormalizeListWithVin(vehicles)
    }
  } catch (error) {
    throw errorHandler('Unexpected error occurred while fetching the vehicles', error)
  }
}

const getCameraImageByVINFn = async ({ vin, cameraID }: GetCameraImageByVINQueryVariables) => {
  try {
    const { data } = await client.graphql({
      query: getCameraImageByVIN,
      variables: { vin, cameraID }
    })
    return { data: CameraImageSchema().parse(data.getCameraImageByVIN) }
  } catch (error) {
    throw errorHandler(
      `Unexpected error occurred while fetching the camera image for ${vin} for CameraID ${cameraID}`,
      error
    )
  }
}

const getVideoStreamURLFn = async ({ vin, cameraID }: GetCameraImageByVINQueryVariables) => {
  try {
    const { data } = await client.graphql({
      query: getVideoStreamingURL,
      variables: { vin, cameraID }
    })
    return { data: VideoStreamSchema().parse(data.getVideoStreamingURL) }
  } catch (error) {
    throw errorHandler(
      `Unexpected error occurred while fetching the camera ${cameraID} stream for ${vin}`,
      error
    )
  }
}

export const vehiclesApi = createApi({
  reducerPath: 'vehicles',
  baseQuery: graphqlRequestBaseQuery({
    url: '/graphql'
  }),
  tagTypes: ['Vehicle', 'CameraImage', 'CameraStreamURL', 'VideoStreamingError'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    listVehicles: builder.query<GenericRecord<Vehicle>, string>({
      queryFn: listVehiclesQueryFn
    }),
    getCameraImageByVIN: builder.query<CameraImage, GetCameraImageByVINQueryVariables>({
      queryFn: getCameraImageByVINFn,
      providesTags: ['CameraImage']
    }),
    getCameraStreamUrl: builder.query<VideoStream, GetVideoStreamingURLQueryVariables>({
      queryFn: getVideoStreamURLFn,
      providesTags: (_) => [{ type: 'CameraStreamURL', id: _?.vin }]
    }),
    continueStream: builder.mutation<ContinueVideoStream, ContinueStreamInput>({
      queryFn: async (variables) => {
        try {
          const { data } = await client.graphql({
            query: continueStream,
            variables: {
              input: variables
            }
          })
          return { data: ContinueVideoStreamSchema().parse(data.continueStream) }
        } catch (error) {
          throw errorHandler(
            `Unexpected error occurred while requesting the continue stream for vin: ${variables.vin} and cameraId: ${variables.cameraID}`,
            error
          )
        }
      }
    }),
    onUpdateVehicleSubscription: builder.query<
      GenericRecord<Vehicle>,
      OnUpdateVehicleSubscriptionVariables
    >({
      queryFn: listVehiclesQueryFn,
      onCacheEntryAdded: async (_, { cacheDataLoaded, cacheEntryRemoved, updateCachedData }) => {
        const updateCache = (data: Vehicle) => {
          const vehicle = VehicleSchema().parse(
            withConnectivityLatency(transformDeviationsWithVin(data))
          )
          updateCachedData((draft) => {
            draft[vehicle.vin] = vehicle
          })
        }

        await cacheDataLoaded
        if (import.meta.env.DEV) {
          // mockSubscription({
          //   maxIterations: WAYPOINTS.length,
          //   mockFn: mockVehicle,
          //   callback: (data) => {
          //     if (data.onUpdateVehicle) {
          //       const updatedVehicle = VehicleSchema().parse(
          //         withConnectivityLatency(transformDeviationsWithVin(data.onUpdateVehicle))
          //       )
          //       updateCache(updatedVehicle)
          //     }
          //   }
          // })
          registerVehiclesDevTools((data) => {
            updateCache(data.onUpdateVehicle)
          })
          await cacheEntryRemoved
        } else {
          const connection = client.graphql({ query: onUpdateVehicle }).subscribe({
            next: ({ data }) => {
              if (!data.onUpdateVehicle) {
                console.error(
                  '[onUpdateVehicleSubscription]: Please make sure all the attributes are returned in the mutation'
                )
              }
              updateCache(data.onUpdateVehicle)
            },
            error: (error) => console.warn(error)
          })
          cacheEntryRemoved
            .then(() => {
              connection.unsubscribe()
            })
            .catch((error) => console.error(error))
        }
      }
    }),
    onUpdateVideoStreamingErrorSubscription: builder.query<
      GenericRecord<VideoStreamingError>,
      OnUpdateVideoStreamingErrorSubscriptionVariables
    >({
      // no initial query is needed here as the error is only relying on the subscription
      queryFn: () => ({ data: {} }),
      providesTags: (result) =>
        result
          ? [
              ...normalizeRecordData(result).map((data) => ({
                type: 'VideoStreamingError' as const,
                id: createVideoStreamingErrorIdentifier(data)
              })),
              { type: 'VideoStreamingError', id: 'LIST' }
            ]
          : [{ type: 'VideoStreamingError', id: 'LIST' }],
      onCacheEntryAdded: async (
        variables,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
      ) => {
        const updateCache = (data: VideoStreamingError) => {
          const videoStreamingError = VideoStreamingErrorSchema().parse(data)
          const key = createVideoStreamingErrorIdentifier(videoStreamingError)
          updateCachedData((draft) => {
            draft[key] = videoStreamingError
          })
        }

        await cacheDataLoaded
        if (import.meta.env.DEV) {
          registerVideoStreamDevTools((data) => {
            updateCache(data.onUpdateVideoStreamingError)
          })
          await cacheEntryRemoved
        }

        const connection = client
          .graphql({ query: onUpdateVideoStreamingError, variables })
          .subscribe({
            next: ({ data }) => {
              if (!data.onUpdateVideoStreamingError) {
                console.error(
                  '[onUpdateVideoStreamingErrorSubscription]: Please make sure all the attributes are returned in the mutation'
                )
              }
              updateCache(data.onUpdateVideoStreamingError)
            },
            error: (error) => console.warn(error)
          })
        cacheEntryRemoved
          .then(() => {
            connection.unsubscribe()
          })
          .catch((error) => console.error(error))
      }
    })
  })
})

export const {
  useListVehiclesQuery,
  useLazyListVehiclesQuery,
  useOnUpdateVehicleSubscriptionQuery,
  useGetCameraImageByVINQuery,
  useLazyGetCameraImageByVINQuery,
  useGetCameraStreamUrlQuery,
  useLazyGetCameraStreamUrlQuery,
  useContinueStreamMutation,
  useLazyOnUpdateVideoStreamingErrorSubscriptionQuery
} = vehiclesApi

import Button from 'components/actions/Button'
import NoContent from './NoContent'

interface FleetsTableProps {
  fleets: any[]
  isLoading: boolean
}

// TODO: move into a feature utils
const tableHeaders = {
  id: 'ID',
  _: ''
}

const FleetsTable = ({ fleets, isLoading }: FleetsTableProps) => {
  return (
    <div className="flex flex-col">
      <table data-testid="exceptions-table" className="table-head-filled table-bordered table">
        <thead>
          <tr>
            {Object.values(tableHeaders).map((label) => (
              <th key={label}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? 'Loading...'
            : fleets.map((_, idx) => (
                // TODO: replace this by the specific FleetSettingsRow component
                <li key={idx}>User</li>
              ))}
        </tbody>
      </table>
      {!fleets.length && !isLoading && (
        <NoContent
          title="No fleets yet, but they will appear here"
          body="As of now, there are no fleets in the Control Center."
          button={
            <Button data-testid="no-content-fleets-button" style="primary">
              <span className="rioglyph rioglyph-plus" />
              Add new fleet
            </Button>
          }
        />
      )}
    </div>
  )
}

export default FleetsTable

import { useState } from 'react'
import { Position } from '@rio-cloud/rio-uikit/mapTypes'
import ContentBox from 'features/simulations/ContentWrapper'
import GenerateRouteForm from 'features/simulations/GenerateRouteForm'
import ScenariosList from 'features/simulations/ScenariosList'
import SimulationMap from 'features/simulations/SimulationMap'
import UploadScenarioForm from 'features/simulations/UploadScenarioForm'
import useGetRoute from 'hooks/useGetRoute'

const domain = import.meta.env.VITE_SIMULATION_ROUTE

/*
 * TODO: open ContentBox dynamically (one open - others closed).
 * TODO: open Generate Route when a Map interacton happens.
 */
const SimulationsPage = () => {
  const initialPosition = { lat: 0, lng: 0 }
  const [selectedScenario, setSelectedScenario] = useState<string | undefined>(undefined)
  const [startPoint, setStartPoint] = useState<Position | undefined>(initialPosition)
  const [intermediatePoint, setIntermediatePoint] = useState<Position | undefined>(initialPosition)
  const [destinationPoint, setDestinationPoint] = useState<Position | undefined>(initialPosition)
  const [route, setRoute] = useState<Position[] | undefined>(undefined)
  const isIntermediateValid = intermediatePoint?.lat !== 0 && intermediatePoint?.lng !== 0

  useGetRoute({ startPoint, intermediatePoint, destinationPoint, setRoute })

  const selectScenario = (scenario: string) => {
    if (scenario === selectedScenario) {
      setSelectedScenario(undefined)
    } else {
      setSelectedScenario(scenario)
    }
  }

  const resetIntermediatePoint = () => {
    setIntermediatePoint(initialPosition)
  }

  const clearRoute = () => {
    setStartPoint(initialPosition)
    setIntermediatePoint(initialPosition)
    setDestinationPoint(initialPosition)
    setRoute(undefined)
  }

  return (
    <div className="relative size-full overflow-hidden">
      <SimulationMap
        route={route}
        hasIntermediate={isIntermediateValid}
        setStartPosition={setStartPoint}
        setDestinationPosition={setDestinationPoint}
        handleIntermediatePoint={setIntermediatePoint}
      />
      <div className="absolute left-20 top-20 mb-20 grid w-[360px] grid-cols-1 gap-10">
        <ContentBox header="Generate route">
          <GenerateRouteForm
            route={route}
            clearRoute={clearRoute}
            startPoint={startPoint}
            setStartPoint={setStartPoint}
            intermediatePoint={intermediatePoint}
            setIntermediatePoint={setIntermediatePoint}
            destinationPoint={destinationPoint}
            setDestinationPoint={setDestinationPoint}
            resetIntermediatePoint={resetIntermediatePoint}
            hasIntermediatePoint={isIntermediateValid}
          />
        </ContentBox>
        <ContentBox header="Upload scenario">
          <UploadScenarioForm domain={domain} />
        </ContentBox>
        <ContentBox header="Available scenarios">
          <ScenariosList selected={selectedScenario} selectScenario={selectScenario} />
        </ContentBox>
      </div>
    </div>
  )
}

export default SimulationsPage

import { PropsWithChildren } from 'react'

interface SidebarSectionRowProps {
  label: string
}

const SidebarSectionRow = ({ label, children }: PropsWithChildren<SidebarSectionRowProps>) => {
  return (
    <li className="grid grid-cols-4 gap-25 py-5">
      <p className="col-span-1 font-bold text-gray-dark">{label}</p>
      <p className="col-span-3">{children}</p>
    </li>
  )
}

export default SidebarSectionRow

import { scenarioButtonActions, SimulationState } from './utils'
import { diffInSeconds, formatDurationClockTime } from '../../helper/time'
import { useEffect, useMemo, useState } from 'react'
interface ScenarioItemProps {
  vin: string
  selected: string | undefined
  isLoading: boolean
  onClick(vin: string): void
  selectScenario(i: string): void
  simulationState: SimulationState
  startedAt?: string
}

const ScenarioItem = ({
  vin,
  selected,
  isLoading,
  selectScenario,
  onClick,
  simulationState,
  startedAt
}: ScenarioItemProps) => {
  const [timeDifference, setTimeDifference] = useState('')
  const isSelected = selected === vin
  const icon = scenarioButtonActions.get(simulationState)?.icon
  // Memoize the 'date' variable so it doesn't change unnecessarily on every render
  const date = useMemo(() => (startedAt ? new Date(startedAt) : null), [startedAt])
  useEffect(() => {
    if (date != null) {
      const intervalId = setInterval(() => {
        const differenceInSeconds = diffInSeconds(new Date(), date)
        const formattedTime = formatDurationClockTime(differenceInSeconds)
        setTimeDifference(`${formattedTime}`)
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }, [date])
  return (
    <div
      className={`grid w-full grid-cols-10 items-center justify-between p-10 ${isSelected ? 'bg-primary' : 'bg-white'}`}
    >
      <div className="col-span-6">
        <div
          className="flex w-full cursor-pointer items-center justify-between"
          onClick={() => selectScenario(vin)}
        >
          <p>{vin}</p>
        </div>
      </div>
      <div className="col-span-3">
        {startedAt != null && <p className="text-success">{timeDifference}</p>}
      </div>
      <div className="col-span-1 flex justify-end">
        <button
          disabled={isLoading}
          onClick={() => onClick(vin)}
          className={`flex cursor-pointer items-center justify-center rounded-xl border p-5 ${isSelected ? 'border-white' : 'border-gray-dark'}`}
        >
          <span className={`${icon} text-16`} />
        </button>
      </div>
    </div>
  )
}

export default ScenarioItem

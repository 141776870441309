import { useEffect, useState } from 'react'
import { setHeaders, SimulationState } from 'features/simulations/utils'
import { Position } from 'API'

const domain = import.meta.env.VITE_SIMULATION_ROUTE

interface Scenario {
  vin: string
  state: SimulationState
  startedAt?: string
  routeWaypoints: Position[]
}

const useGetScenarios = () => {
  const [scenarios, setScenarios] = useState<Scenario[] | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const fetchScenarios = async () => {
    const url = `${domain}/simulator-adapter-service/api/v1/simulation`
    setIsLoading(true)

    try {
      const res = await fetch(url, {
        method: 'GET',
        headers: await setHeaders()
      })

      const payload = (await res.json()) as Scenario[]
      setScenarios(payload)
    } catch (error) {
      console.error(error)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!scenarios) {
      fetchScenarios()
    }
  }, [scenarios])

  return {
    scenarios: isError ? [] : scenarios,
    isLoading,
    isError,
    refresh: fetchScenarios
  }
}

export default useGetScenarios

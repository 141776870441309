import { useState } from 'react'
import ScenarioItem from './ScenarioItem'
import { Action, scenarioButtonActions, setHeaders } from './utils'
import ErrorMessage from './ErrorMessage'
import useGetScenarios from 'hooks/useGetScenarios'
import ScenarioItemSkeleton from './ScenarioItemSkeleton'

const domain = import.meta.env.VITE_SIMULATION_ROUTE

interface ScenariosListProps {
  selected: string | undefined
  selectScenario(i: string): void
}

const ScenariosList = ({ selected, selectScenario }: ScenariosListProps) => {
  const [isTriggeringSimulation, setIsTriggeringSimulation] = useState<boolean>(false)
  const [isTriggerSimulationError, setIsTriggerSimulationError] = useState<boolean>(false)
  const { scenarios, isLoading, refresh } = useGetScenarios()

  const closeError = () => {
    setIsTriggerSimulationError(false)
  }

  // handleButtonAction(action, vin ) -> decide wich method will be called according to the action
  const triggerSimulation = async (vin: string) => {
    const scenario = scenarios?.find((item) => item.vin === vin)
    let url = ''
    if (scenario !== null && scenario !== undefined) {
      switch (scenarioButtonActions.get(scenario.state)?.action) {
        case Action.START:
          url = `${domain}/simulator-adapter-service/api/v1/simulation/start/${vin}`
          break
        case Action.STOP:
          url = `${domain}/simulator-adapter-service/api/v1/simulation/stop/${vin}`
          break
        case Action.INITIALIZE:
          url = `${domain}/simulator-adapter-service/api/v1/simulation/initialize/${vin}`
          break
      }
    }
    setIsTriggerSimulationError(false)
    setIsTriggeringSimulation(true)
    try {
      await fetch(url, {
        method: 'POST',
        headers: await setHeaders()
      })
    } catch (error) {
      setIsTriggerSimulationError(true)
      console.error(error)
    } finally {
      setIsTriggeringSimulation(false)
      refresh()
    }
  }

  return (
    <div className="w-full">
      {isTriggerSimulationError && (
        <ErrorMessage message="Error while triggering simulation." onClose={closeError} />
      )}
      {/* TODO - move the button bellow into TextButton component */}
      <button
        disabled={isLoading}
        onClick={refresh}
        className="mb-10 w-max cursor-pointer rounded-md px-5 py-2 text-white hover:bg-white hover:text-gray-darkest disabled:cursor-auto disabled:bg-transparent disabled:text-gray-dark"
      >
        Refresh list
      </button>
      {isLoading ? (
        <ScenarioItemSkeleton />
      ) : !scenarios?.length ? (
        <i className="flex w-full justify-center p-10 text-white">No scenario available</i>
      ) : (
        <ul className="max-h-[200px] w-full list-none overflow-y-auto pr-5">
          {scenarios.map((_) => (
            <li key={_.vin} className="mb-2">
              <ScenarioItem
                vin={_.vin}
                selected={selected}
                simulationState={_.state}
                selectScenario={selectScenario}
                isLoading={isTriggeringSimulation}
                onClick={triggerSimulation}
                startedAt={_.startedAt}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default ScenariosList

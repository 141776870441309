type DatePattern = 'dd/mm/YYYY' | 'YYYY/mm/dd' | 'dd-mm-YYYY' | 'YYYY-mm-dd'
type TimePattern = 'HH:mm:ss' | 'HH:mm'

export const getDateFromString = (dateString: string | null | undefined) => {
  if (!dateString) {
    return undefined
  }
  const date = new Date(dateString)
  if (date.toString() === 'Invalid Date') {
    return undefined
  }
  return date
}

export const addZero = (t: number) => (t < 10 ? `0${t}` : t)

export const formatDate = (date: Date, pattern?: DatePattern): string => {
  const day = addZero(date.getDate())
  const month = addZero(date.getMonth() + 1)
  const year = date.getFullYear()
  return {
    'dd/mm/YYYY': `${day}/${month}/${year}`,
    'YYYY/mm/dd': `${year}/${month}/${day}`,
    'dd-mm-YYYY': `${day}-${month}-${year}`,
    'YYYY-mm-dd': `${year}-${month}-${day}`
  }[pattern || 'dd/mm/YYYY']
}

export const formatDuration = (durationInSeconds?: number) => {
  if (durationInSeconds === undefined) {
    return undefined
  }

  const hours = Math.floor(durationInSeconds / 3600)
  const minutes = Math.floor((durationInSeconds % 3600) / 60)

  /**
   * Please, keep those negative values on.
   * Those can be used as issue identifiers regarding the duration.
   */
  if (durationInSeconds < 0) {
    const negativeHours = hours + 1
    switch (true) {
      case negativeHours === 0 && minutes < 1:
        return 'Less than 1min'
      case negativeHours === 0:
        return `${minutes}min`
      case negativeHours < 0 && minutes === 0:
        return `${negativeHours}h`
      default:
        return `${negativeHours}h ${Math.abs(minutes)}min`
    }
  }

  switch (true) {
    case hours === 0 && minutes < 1:
      return 'Less than 1min'
    case hours === 0:
      return `${minutes}min`
    case hours > 0 && minutes === 0:
      return `${hours}h`
    default:
      return `${hours}h ${minutes}min`
  }
}

export const formatDurationClockTime = (durationInSeconds: number): string => {
  const hours = Math.floor(durationInSeconds / 3600)
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
  const seconds = Math.floor(durationInSeconds % 60)

  return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`
}

export const formatTime = (time: Date, pattern?: TimePattern): string => {
  const hours = addZero(time.getHours())
  const minutes = addZero(time.getMinutes())
  const seconds = addZero(time.getSeconds())
  return {
    'HH:mm:ss': `${hours}:${minutes}:${seconds}`,
    'HH:mm': `${hours}:${minutes}`
  }[pattern || 'HH:mm']
}

/**
 * Calculates the relative difference in seconds between two dates.
 * @param {Date} future - The current date.
 * @param {Date} past - The previous date.
 * @returns {number} The relative difference in seconds.
 */
export const diffInSeconds = (future: Date, past: Date) => {
  return (future.getTime() - past.getTime()) / 1000
}

/**
 * Calculates the relative time between two dates.
 * @param {Date} now - The current date.
 * @param {Date} past - The past date.
 * @returns {string} The relative time between the two dates.
 */
export const getRelativeTime = (now: Date, past: Date) => {
  const minute = 60
  const hour = 60 * minute
  const day = 24 * hour
  const week = 7 * day

  const diff = (now.getTime() - past.getTime()) / 1000

  switch (true) {
    case diff < minute:
      return 'a few seconds ago'
    case diff < 4.5 * minute:
      return 'a few minutes ago'
    case diff < hour:
      const roundedMinutes = Math.ceil(diff / minute / 5) * 5
      return `${roundedMinutes} minutes ago`
    case diff < 120 * minute:
      return 'an hour ago'
    case diff < day:
      const hours = Math.floor(diff / hour)
      return `${hours} hours ago`
    case diff < 2 * day:
      return 'yesterday'
    case diff < week:
      const days = Math.floor(diff / day)
      return `${days} days ago`

    default:
      const weeks = Math.floor(diff / week)
      return `${weeks > 1 ? weeks : 'a'} week${weeks > 1 ? 's' : ''} ago`
  }
}

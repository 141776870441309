import NotificationsContainer from 'components/content/Notifications'
import ApplicationLayout from 'components/ApplicationLayout'
import { Outlet } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { DEFAULT_LOCALE } from 'store/i18n/lang'
import { getDisplayMessages, getLocale } from 'store/i18n/langSlice'
import { useAppSelector } from 'store/setup/hooks'
import AppHeader from 'features/header/AppHeader'
import SettingsSidebarWrapper from 'features/settings/SettingsSidebarWrapper'

const SettingsLayout = () => {
  const userLocale = useAppSelector(getLocale)
  const displayMessages = useAppSelector(getDisplayMessages)

  if (!displayMessages || !userLocale) {
    return null
  }

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      key={userLocale}
      locale={userLocale}
      messages={displayMessages}
    >
      <ApplicationLayout>
        <ApplicationLayout.Header>
          <AppHeader title="Settings" />
        </ApplicationLayout.Header>
        <ApplicationLayout.Sidebar className="right">
          <SettingsSidebarWrapper />
        </ApplicationLayout.Sidebar>
        <ApplicationLayout.Body innerClassName="p-0 padding-0">
          <NotificationsContainer />
          <Outlet />
        </ApplicationLayout.Body>
      </ApplicationLayout>
    </IntlProvider>
  )
}

export default SettingsLayout

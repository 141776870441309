import { useSelector } from 'react-redux'
import { ActiveSidebar, getActiveSidebar } from 'store/slices/settingsPageSlice'
import UsersSettingsSidebar from './UsersSettingsSidebar'
import TeamsSettingsSidebar from './TeamsSettingsSidebar'

const SettingsSidebarWrapper = () => {
  const activeSidebar = useSelector(getActiveSidebar)

  switch (activeSidebar) {
    case ActiveSidebar.USERS:
      return <UsersSettingsSidebar />
    case ActiveSidebar.TEAMS:
      return <TeamsSettingsSidebar />
    case ActiveSidebar.FLEETS:
    default:
      return
  }
}

export default SettingsSidebarWrapper
